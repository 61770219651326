/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import UserService from "services/users";

const UserAbove = (props) => {
  const { data } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (data) {
      getUserAbove();
    }
  }, [data]);

  const getUserAbove = async () => {
    try {
      const res: any = await UserService.getUserBranchAboveByUserId({
        params: {
          userId: data._id,
        },
      });
      if (res) setUsers(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="grid">
      {users.map((user, index) => (
        <div key={index} className="col-12">
          <div>
            Refer: <span className="font-bold">{index + 1}</span>
          </div>
          {user.is_leader && <span className="font-bold">Leader</span>}
          <div>
            Id: <span className="font-bold">{user.refer_code}</span>
          </div>
          <div>
            Email: <span className="font-bold">{user.email}</span>
          </div>

          <hr />
        </div>
      ))}
    </div>
  );
};

export default UserAbove;
