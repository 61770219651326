import qs from "query-string";
import { request, parseErrorResponse } from "./request";
import { IRequestData } from "./s3-request";

const search = (req: { body: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/revenues/search`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRevenueHistories = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(
        `admin/revenues/getRevenueHistoryByRevenueId/${req.params.id}?${query}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const RevenueService = {
  search,
  getRevenueHistories,
};

export default RevenueService;
