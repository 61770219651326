import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { forwardRef, useImperativeHandle, useState } from "react";

const View = (props, ref) => {
  const { onSubmit, onShow, header, onCancel } = props;

  const [showDetail, setShowDetail] = useState(false);

  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setShowDetail(true);
    },

    close: () => {
      setShowDetail(false);
    },

    setLoading: (flg) => {
      setLoading(flg);
    },
  }));

  const cancel = () => {
    setShowDetail(false);
  };

  const submit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Dialog
      header={header}
      visible={showDetail}
      modal
      contentClassName="over"
      className={`p-fluid ${props?.className}`}
      style={{ width: "1000px", maxWidth: "1000px" }}
      footer={
        <>
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={() => (onCancel ? onCancel() : cancel())}
          />
          <Button
            label="Save"
            icon="pi pi-check"
            loading={loading}
            className="p-button-text"
            onClick={submit}
          />
        </>
      }
      onHide={() => (onCancel ? onCancel() : cancel())}
      onShow={onShow}
    >
      <div className="p-2">{props.children}</div>
    </Dialog>
  );
};

export default forwardRef(View);
