import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { Button, InputText, Toast } from "primereact";
import { Dropdown } from "primereact/dropdown";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";

const currencies = [
  {
    name: "STOCK",
    value: "stock",
  },
  {
    name: "VND",
    value: "vnd",
  },
];
const networks = [
  {
    name: "BANK",
    value: "bank",
  },
  {
    name: "BSC",
    value: "bsc",
  },
  {
    name: "ETHEREUM",
    value: "ethereum",
  },
  {
    name: "VZONEX",
    value: "vzonex",
  },
  {
    name: "TRON",
    value: "tron",
  },
];

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const handleAdd = () => {
    setState((state) => [...state, { index: nanoid() }]);
  };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };

  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "banks",
          value: state,
        },
      });

      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "banks",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Banks and Wallets</h4>
            <Button
              onClick={handleAdd}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3"
              aria-label="Search"
            />
          </div>
          {state.map((item, index) => {
            return (
              <div className="grid" key={index}>
                <div className="field col-4">
                  <span className="p-float-label">
                    <Dropdown
                      value={item.network}
                      className="w-full"
                      options={networks}
                      optionValue={"value"}
                      optionLabel={"name"}
                      onChange={(e) =>
                        changeHandler(e.value, "network", item.index)
                      }
                      placeholder="Network"
                    />
                    <label htmlFor="network">Network</label>
                  </span>
                </div>
                <div className="field col-4">
                  <span className="p-float-label">
                    <Dropdown
                      value={item.currency}
                      className="w-full"
                      options={currencies}
                      optionValue={"value"}
                      optionLabel={"name"}
                      onChange={(e) =>
                        changeHandler(e.value, "currency", item.index)
                      }
                      placeholder="Currency"
                    />
                    <label htmlFor="currency">Currency</label>
                  </span>
                </div>
                <div className="field col-4 mb-2 md:md-0">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.bank_name}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "bank_name", item.index)
                      }
                    />
                    <label htmlFor="bank">Bank</label>
                  </span>
                </div>
                <div className="field col-4">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.account_name}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(
                          e.target.value,
                          "account_name",
                          item.index
                        )
                      }
                    />
                    <label htmlFor="accountName" className="white-space-nowrap">
                      Account Name
                    </label>
                  </span>
                </div>
                <div className="field col-4">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.account_number}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(
                          e.target.value,
                          "account_number",
                          item.index
                        )
                      }
                    />
                    <label
                      htmlFor="accountNumber"
                      className="white-space-nowrap"
                    >
                      Account Number
                    </label>
                  </span>
                </div>
                <div className="field col-3">
                  <span className="p-float-label">
                    <InputText
                      required
                      value={item.memo}
                      className="w-full"
                      onChange={(e) =>
                        changeHandler(e.target.value, "memo", item.index)
                      }
                    />
                    <label htmlFor="memo">Memo</label>
                  </span>
                </div>
                <div className="field col-4 md:col-1">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}

          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
