/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputSwitch } from "primereact";
import "../styles.scss";
import InvestService from "services/invests";
import VUpload from "components/v-upload";

const Terms = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const [details, setDetails] = useState(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
      });
  }, [data]);

  const onChange = (value, name) => {
    setDetails((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await InvestService.update({
          params: {
            id: data._id,
          },
          body: {
            ...data,
            ...details,
          },
        });
      } else {
        showToast(toast, "error", "Please choose package!");
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  console.log({
    details,
  });

  return (
    <>
      {details && (
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="is_term">Enable/Disable</label>
            <br />
            <InputSwitch
              checked={details.is_term}
              onChange={(e) => onChange(e.value, "is_term")}
            />
          </div>
          <div className="field col-12">
            <VUpload
              fileType=".pdf"
              label="PDF"
              urlFile={details.term}
              setUrlFile={(file) => onChange(file, "term")}
            />
            {/* <Editor
              style={{ height: "500px" }}
              value={details.term}
              onTextChange={(e) => onChange(e.htmlValue, "term")}
            /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default forwardRef(Terms);
