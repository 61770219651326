import { Editor } from "primereact/editor";
import { nanoid } from "nanoid";
import { InputText } from "primereact/inputtext";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Supports from "services/supports";
import { showToast } from "utils/common";
import { Image } from "primereact";

const DetailSupport = (props, ref) => {
  const { data, reload, toast, onCancle, setLoading } = props;
  const emptyData = {
    id: nanoid(),
    title: "",
    email: "",
    fullName: "",
    reply: "",
    user: null,
    value: {},
  };
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Supports.replySupportById({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success");
      onCancle();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleChangeReply = (content) => {
    setDetails((data) => ({
      ...data,
      reply: content,
    }));
  };

  const isVideo = (src: any) => {
    const type = src.split(".")[src.split(".").length - 1];
    switch (type.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
        // etc
        return true;
    }
    return false;
  };

  const renderAttacments = (attachments) => {
    return attachments.map((a) =>
      isVideo(a) ? (
        <video width={200} height={200} controls>
          <source src={a} />
        </video>
      ) : (
        <Image src={a} width={"200"} height="200" preview />
      )
    );
  };

  return (
    <div className="w-full">
      <div className="py-2">
        <label htmlFor="" className="mb-2">
          Name
        </label>
        <InputText
          className="mt-2"
          disabled
          id="name"
          value={details.user?.fullName}
          onChange={(e) => onInputChange(e, "name")}
          required
          autoFocus
        />
      </div>
      <div className="py-2">
        <label htmlFor="" className="mb-2">
          Email
        </label>
        <InputText
          className="mt-2"
          disabled
          id="email"
          value={details.user?.email}
          onChange={(e) => onInputChange(e, "email")}
          required
          autoFocus
        />
      </div>
      <div className="py-2 w-2/3">
        <label htmlFor="" className="mb-2">
          Title
        </label>
        <InputText
          className="mt-2"
          disabled
          id="title"
          value={details.title}
          onChange={(e) => onInputChange(e, "title")}
          required
          autoFocus
        />
      </div>

      <div className="mt-2 mb-2 border-1 p-2 border-dashed border-round-2xl">
        {Object.keys(details.value || {}).map((key) => {
          if (key === "attachments")
            return (
              <div className="my-2">
                <div>Attachments :</div>
                <div className="flex w-full">
                  {renderAttacments(details.value[key] || [])}
                </div>
              </div>
            );
          return (
            <>
              {key} : <span className="font-bold">{details.value[key]} </span>
            </>
          );
        })}
      </div>

      <div className="">
        <label htmlFor="" className="mb-2">
          Reply :
        </label>
        <Editor
          className="h-10rem"
          value={details?.reply}
          onTextChange={(e) => handleChangeReply(e.htmlValue)}
        />
        <br />
      </div>
    </div>
  );
};

export default forwardRef(DetailSupport);
