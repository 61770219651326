/* eslint-disable react-hooks/exhaustive-deps */
import VStockDropdown from "components/v-stock";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import {
  Button,
  Column,
  DataTable,
  InputNumber,
  InputText,
  InputTextarea,
  MultiSelect,
  OverlayPanel,
  Panel,
} from "primereact";
import Users from "services/users";
import { MasterContext } from "contexts/MasterContext";
import VLocation from "components/v-location";
import { renderAmount } from "utils/render";
import AllotmentService from "services/allotments";
import { Toast } from "primereact/toast";
import { formatNumber, showToast } from "utils/common";

const AllotmentPerQnt = ({ type }) => {
  const defaultFilter = {
    start: null,
    end: null,
    stockFrom: null,
    stockTo: null,
    groups: null,
    objects: null,
    branch: "",
    address: null,
    amountFrom: 0,
    amountTo: 0,
    rateFrom: 1,
    rateTo: 0,
    type,
  };
  const defaultLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    sortField: "amount",
    sortOrder: -1,
  };
  const { groups, objects } = useContext(MasterContext);

  const [globalFilter, setGlobalFilter] = useState(defaultFilter);

  const [lazyParams, setLazyParams]: any = useState(defaultLazyParams);
  const [totalRecords, setTotalRecords] = useState(0);

  const [checkUserResults, setCheckUserResults] = useState([]);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [amount, setAmount] = useState(0);
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [rateFrom, setRateFrom] = useState(1);
  const [rateTo, setRateTo] = useState(0);
  const [description, setDescription] = useState("");
  const [branchText, setBranchText] = useState("");
  const [sum, setSum] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);

  const toast = useRef(null);
  const op = useRef(null);
  const initLoadedRef = useRef(true);

  useEffect(() => {
    if (!initLoadedRef.current) {
      onCheckUsers();
    }
    initLoadedRef.current = false;
  }, [lazyParams]);

  useEffect(() => {
    setGlobalFilter(defaultFilter);
    setLazyParams(defaultLazyParams);
  }, [type]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setGlobalFilter({ ...globalFilter, branch: branchText });
    }, 500);
    return () => clearTimeout(timerId);
  }, [branchText]);

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (value, name) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clear = () => {
    setGlobalFilter(defaultFilter);
    setCheckUserResults([]);
    setTotalRecords(0);
    setAmount(0);
    setDescription("");
    setBranchText("");
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const handleResetPopup = () => {
    if (type === "quantity") {
      setAmountFrom(0);
      setAmountTo(0);
      return setGlobalFilter({
        ...globalFilter,
        amountFrom: 0,
        amountTo: 0,
      });
    }
    setRateTo(0);
    setRateFrom(1);
    return setGlobalFilter({
      ...globalFilter,
      rateTo: 0,
      rateFrom: 1,
    });
  };

  const handleSubmitPopup = () => {
    if (type === "quantity") {
      !!amountFrom && setAmountFrom(0);
      !!amountTo && setAmountTo(0);
      return setGlobalFilter({ ...globalFilter, amountFrom, amountTo });
    }
    !!rateTo && setRateTo(0);
    setRateFrom(1);
    return setGlobalFilter({ ...globalFilter, rateTo });
  };

  const onCheckUsers = () => {
    try {
      setLoadingCheck(true);
      Users.getUserAllotmentAvailableToken({
        body: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      })
        .then((res: any) => {
          if (res && res.docs) {
            setSum(res.sum);
            setCheckUserResults(res.docs);
            setTotalRecords(res.totalDocs);
          } else setCheckUserResults([]);
        })
        .finally(() => setLoadingCheck(false));
    } catch (error) {}
  };

  const onSubmitAllotments = () => {
    setLoadingSubmit(true);
    try {
      AllotmentService.allotmentWithPercentOrQnt({
        body: {
          ...globalFilter,
          amount,
          description,
        },
      })
        .then((resp) => {
          if (resp) {
            clear();
            showToast(toast, "success", "Allotments Success!");
            setLoadingSubmit(false);
          }
        })
        .catch((error) => {
          showToast(toast, "error", error?.errors || "");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const exportExcel = async () => {
    try {
      setLoadingExport(true);
      const res: any = await AllotmentService.exportData({
        body: {
          ...lazyParams,
          ...globalFilter,
          rows: 10000,
          page: 1,
        },
      });
      import("xlsx").then((xlsx) => {
        const _data = res.docs.map((item, index) => ({
          STT: index + 1,
          ID: item.user.refer_code,
          Email: item.user.email,
          Token: item.token?.toUpperCase(),
          Amount: item.balances[checkUserResults[0]?.token],
          Percent: handlePercentAmount(
            item.balances[checkUserResults[0]?.token]
          ),
          AmountReceived: `${handleAmountReceived(
            item.balances[checkUserResults[0]?.token]
          )}
          (${String(globalFilter.stockTo)?.toUpperCase()})`,
        }));
        const worksheet = xlsx.utils.json_to_sheet(_data);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "Airdrop Allotments");
        setLoadingExport(false);
      });
    } catch (error) {
      setLoadingExport(false);
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const isDisableButton = () => {
    return (
      !globalFilter.stockFrom ||
      !globalFilter.stockTo ||
      (type !== "rate" && !amount) ||
      (type === "quantity" && !globalFilter.amountTo) ||
      (type === "rate" && (!globalFilter.rateFrom || !globalFilter.rateTo))
    );
  };

  const handlePercentAmount = (amountBalance) => {
    return +((amountBalance / sum) * 100);
  };

  const handleAmountReceived = (amountBalance) => {
    switch (type) {
      case "percent":
        const percent = handlePercentAmount(amountBalance);
        return formatNumber((amount * percent) / 100);
      case "quantity":
        return formatNumber(amount / totalRecords);
      case "rate":
        return formatNumber((amountBalance * rateTo) / rateFrom);
      default:
        break;
    }
  };

  return (
    <div className="grid">
      <Toast ref={toast}></Toast>
      <Panel header="Filter" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Groups</label>
            <MultiSelect
              value={globalFilter.groups}
              options={groups}
              onChange={(e) =>
                onSearch(e.value?.length > 0 ? e.value : null, "groups")
              }
              optionLabel="name"
              optionValue="key"
              placeholder="Select a group"
              className="w-full"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Objects</label>
            <MultiSelect
              value={globalFilter.objects}
              options={objects}
              onChange={(e) =>
                onSearch(e.value?.length > 0 ? e.value : null, "objects")
              }
              optionLabel="name"
              optionValue="id"
              placeholder="Select a object"
              className="w-full"
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12">
            <label htmlFor="user">Branch User</label>
            <InputText
              value={branchText}
              onChange={(e) => setBranchText(e.target.value)}
              className="w-full"
              placeholder="branch@gmail.com"
            />
          </div>
          <div className="field col-12 ">
            <label htmlFor="user">Address</label>
            <VLocation
              address={globalFilter.address}
              setAddress={(a) => onSearch(a, "address")}
            />
          </div>
        </div>
      </Panel>
      <div className="col-12 flex flex-column align-items-center justify-content-center">
        <div className="flex w-full md:w-30rem">
          <p className="text-left w-full ">Stock Apply</p>
        </div>
        <div className="flex align-items-center justify-content-center w-full md:w-30rem">
          <VStockDropdown
            value={globalFilter.stockFrom}
            setValue={(v) => {
              onSearch(v, "stockFrom");
            }}
            className="w-full"
          />
        </div>
      </div>
      {(type === "quantity" || type === "rate") && (
        <div className="col-12 flex flex-column align-items-center justify-content-center">
          <div className="flex w-full md:w-30rem">
            <p className="text-left w-full ">
              {type === "quantity" ? "Range Token" : "Rate Token"}
            </p>
          </div>
          <span
            className="block m-1 p-input-icon-left"
            onClick={(e) => {
              op.current.toggle(e);
            }}
          >
            <i className="pi pi-search" />
            <InputText
              type="text"
              value={
                type === "quantity"
                  ? `${formatNumber(globalFilter.amountFrom)} - ${formatNumber(
                      globalFilter.amountTo
                    )}`
                  : `${formatNumber(globalFilter.rateFrom)} - ${formatNumber(
                      globalFilter.rateTo
                    )}`
              }
              placeholder={type === "quantity" ? "Amount..." : "Rate..."}
              readOnly
              className="w-full md:w-30rem"
            />
            {type === "quantity"
              ? !!globalFilter.amountFrom || !!globalFilter.amountTo
              : !!globalFilter.rateTo && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: 16,
                      zIndex: 999,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleResetPopup();
                    }}
                  >
                    <i className="pi pi-refresh" />
                  </div>
                )}

            <OverlayPanel
              ref={op}
              className="w-[200px]"
              onClick={(e) => e.preventDefault()}
            >
              <div
                className="flex flex-column gap-y-4 w-[200px]"
                onClick={(e) => e.stopPropagation()}
              >
                <p className="text-base font-bold">Amount</p>
                <div className="flex align-items-center">
                  <p className="text-sm mb-0 w-3rem font-medium">From: </p>
                  <InputText
                    type="number"
                    value={
                      type === "quantity"
                        ? !!amountFrom
                          ? amountFrom
                          : ""
                        : !!rateFrom
                        ? rateFrom
                        : ""
                    }
                    onChange={(e) => {
                      type === "quantity"
                        ? setAmountFrom(+e.target.value)
                        : setRateFrom(+e.target.value);
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder="0"
                  />
                </div>
                <div className="flex align-items-center mt-2">
                  <p className="text-sm mb-0 w-3rem font-medium">To: </p>
                  <InputText
                    type="number"
                    value={
                      type === "quantity"
                        ? !!amountTo
                          ? amountTo
                          : ""
                        : !!rateTo
                        ? rateTo
                        : ""
                    }
                    onChange={(e) => {
                      type === "quantity"
                        ? setAmountTo(+e.target.value)
                        : setRateTo(+e.target.value);
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    placeholder="0"
                  />
                </div>

                <Button
                  className="mt-2 flex align-items-center justify-content-center"
                  onClick={(e) => {
                    op.current.toggle(e);
                    handleSubmitPopup();
                  }}
                >
                  Submit
                </Button>
              </div>
            </OverlayPanel>
          </span>
        </div>
      )}
      {type !== "rate" && (
        <div className="col-12 flex flex-column align-items-center justify-content-center">
          <div className="flex w-full md:w-30rem">
            <p className="text-left w-full ">Amount Allotments</p>
          </div>
          <InputNumber
            value={amount}
            onChange={(e) => setAmount(e.value)}
            className="w-full md:w-30rem"
          />
        </div>
      )}
      <div className="col-12 flex flex-column align-items-center justify-content-center">
        <div className="flex w-full md:w-30rem">
          <p className="text-left w-full ">Descriptions</p>
        </div>
        <InputTextarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full md:w-30rem"
        />
      </div>
      <div className="col-12 flex flex-column align-items-center justify-content-center">
        <div className="flex w-full md:w-30rem">
          <p className="text-left w-full ">Stock Receive</p>
        </div>
        <div className="flex align-items-center justify-content-center w-full md:w-30rem">
          <VStockDropdown
            value={globalFilter.stockTo}
            setValue={(v) => onSearch(v, "stockTo")}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-12 flex flex-column md:flex-row align-items-center justify-content-center">
        <Button
          icon="pi pi-check"
          type="button"
          label="Check"
          className="p-button-success mt-2 md:mt-0 md:mr-2"
          loading={loadingCheck}
          disabled={isDisableButton()}
          onClick={(_) => onCheckUsers()}
        />
        <Button
          icon="pi pi-check"
          type="button"
          label="Submit"
          className="p-button-info mt-2 md:mt-0 md:mr-2"
          loading={loadingSubmit}
          disabled={isDisableButton() || !checkUserResults?.length}
          onClick={onSubmitAllotments}
        />
        <Button
          type="button"
          label="Clear"
          icon="pi pi-times"
          onClick={clear}
          className="p-button-danger mt-2 md:mt-0"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help mt-2 md:mt-0 md:ml-2"
          loading={loadingExport}
          onClick={exportExcel}
          disabled={!checkUserResults.length}
        />
      </div>

      <div className="col-12">
        <DataTable
          value={checkUserResults}
          paginator
          dataKey="_id"
          emptyMessage="No data found."
          showGridlines
          lazy
          responsiveLayout="scroll"
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          onPage={onPage}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          onSort={onSort}
        >
          <Column
            field="id"
            header="User ID"
            body={(rowData) => <span>{rowData.user?.refer_code || ""}</span>}
          ></Column>
          <Column
            field="email"
            header="Email"
            body={(rowData) => <span>{rowData.user?.email || ""}</span>}
          ></Column>
          <Column
            field="token"
            header="Token"
            body={(rowData) => String(rowData?.token).toUpperCase()}
          ></Column>
          <Column
            field="amount"
            header="Amount"
            sortable
            body={(rowData) =>
              rowData.balances[checkUserResults[0]?.token]
                ? renderAmount(rowData.balances[checkUserResults[0]?.token])
                : ""
            }
          ></Column>
          <Column
            field=""
            header="Percent"
            body={(rowData) =>
              rowData.balances[checkUserResults[0]?.token] ? (
                <span>
                  {formatNumber(
                    handlePercentAmount(
                      rowData.balances[checkUserResults[0]?.token]
                    )
                  )}
                  %
                </span>
              ) : (
                ""
              )
            }
          ></Column>
          <Column
            field=""
            header="Amount Received"
            body={(rowData) =>
              globalFilter.stockTo ? (
                <span>
                  {handleAmountReceived(
                    rowData.balances[checkUserResults[0]?.token]
                  )}
                  ({String(globalFilter.stockTo)?.toUpperCase()})
                </span>
              ) : (
                ""
              )
            }
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default AllotmentPerQnt;
