/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber, getFromTo } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact";
import TransactionService from "services/transactions";
import { renderAmount, renderHash } from "utils/render";
import { MasterContext } from "contexts/MasterContext";
import VLocation from "components/v-location";
import VAmount from "components/v-amount";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Error", value: "error" },
];

const View = () => {
  const { stocks } = useContext(MasterContext);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: "success",
    type: ["investment", "trust_project"],
    amount: -1,
    stock: null,
    userId: null,
    amountFrom: 0,
    amountTo: 0,
    branch: "",
    address: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [sum, setSum] = useState([]);
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await TransactionService.search({
        body: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setSum(res.sum);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {}
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            // onClick={openNew}
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <Dropdown
          value={globalFilter.stock}
          options={stocks}
          onChange={(e: any) => onSearch("stock", e.value)}
          optionLabel="symbol"
          optionValue="symbol"
          placeholder="Stocks"
          className={"w-15rem m-1"}
          showClear
        />
        <Dropdown
          value={globalFilter.status}
          options={STATUS}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="name"
          placeholder="Status"
          className={"w-15rem m-1"}
          showClear
        />
        <VAmount
          fieldFrom={amountFrom}
          fieldTo={amountTo}
          fieldFromText={`amountFrom`}
          fieldToText={`amountTo`}
          filter={globalFilter}
          onChangeFieldFrom={setAmountFrom}
          onChangeFieldTo={setAmountTo}
          onChangeFilter={setGlobalFilter}
          title={`Amount`}
        />
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("branch", e.target.value)}
            placeholder="Branch..."
            className="w-14rem"
          />
        </span>{" "}
        <div className="field col-12">
          <VLocation
            viewInLine={true}
            wrapperClassName="md:col-2"
            dropdownClassName="w-full"
            address={globalFilter.address}
            setAddress={(a) => onSearch("address", a)}
          />
        </div>
      </div>
    </div>
  );
  const footer = (
    <div>
      {sum.map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s._id} : {formatNumber(Math.abs(s.totalAmount))}
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            footer={footer}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData.user_id?.refer_code}</span>}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "350px" }}
              body={(rowData) => <span>{rowData.user_id?.email || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="amount"
              header="Amount"
              sortable
              body={(rowData) => renderAmount(rowData.amount)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "120px" }}
              field="type"
              header="Type"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="network"
              header="Network"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="token"
              header="Stock"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="from"
              header="From"
              body={(rowData) => getFromTo(rowData.from)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="to"
              header="To"
              body={(rowData) => getFromTo(rowData.to)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "300px" }}
              field="description"
              header="Description"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="hash"
              header="Hash"
              body={(rowData) => renderHash(rowData.hash)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
