import React, { useEffect, useRef, useState } from "react";
import { Button, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState(
    EditorState.createWithContent(
      convertFromRaw({
        entityMap: {},
        blocks: [],
      })
    )
  );
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "landing_page",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const whitepaper = res.setting.value.whitepaper || {
            entityMap: {},
            blocks: [],
            pdf: "",
          };

          const contentState = convertFromRaw({
            ...whitepaper,
            entityMap: whitepaper.entityMap || {},
          });
          setState(EditorState.createWithContent(contentState));
        }
      }
    } catch (error) {}
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const _value = {
        whitepaper: { ...convertToRaw(state.getCurrentContent()) },
      };
      await SettingService.updateSetting({
        body: {
          name: "landing_page",
          value: _value,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const changeHandlerContent = (data) => {
    setState(data);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Whitepaper</h4>
          </div>
          <div className="grid mb-3">
            <div className="field col-12">
              <Editor
                editorState={state}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapper-editor"
                editorClassName="editorClassName"
                onEditorStateChange={changeHandlerContent}
              />
            </div>
          </div>
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
