/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const UserCommisson = (props) => {
  const { data } = props;

  console.log({
    data,
  });

  return (
    <div className="grid">
      <h6>USER AFFILATE</h6>
      {(data?.users_affilate || []).map((user, index) => (
        <div key={index} className="col-12">
          <div>
            Refer: <span className="font-bold">{user.level}</span>
          </div>
          <div>
            Id: <span className="font-bold">{user.refer_code}</span>
          </div>
          <div>
            Email: <span className="font-bold">{user.email}</span>
          </div>
          <hr />
        </div>
      ))}

      <h6>USER REVENUE</h6>
      {(data?.users_revenue || []).map((user, index) => (
        <div key={index} className="col-12">
          <div>
            Id: <span className="font-bold">{user.refer_code}</span>
          </div>
          <div>
            Email: <span className="font-bold">{user.email}</span>
          </div>
          <div>
            Percent: <span className="font-bold">{user.percent}%</span>
          </div>
          <div>
            Amount: <span className="font-bold">{user.amount} USDV</span>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default UserCommisson;
