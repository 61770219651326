/* eslint-disable react-hooks/exhaustive-deps */
import { Column, DataTable } from "primereact";
import { useEffect } from "react";
import { formatLocalTime } from "utils/common";
import { renderAmount } from "utils/render";

const View = ({ data, totalRecords, lazyParams, setLazyParams, onHide }) => {
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  useEffect(() => {
    return () => onHide();
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="grid">
            <div className="col-12 max-w-screen overflow-auto px-10">
              <DataTable
                value={data}
                emptyMessage="No data found."
                dataKey="id"
                size="small"
                scrollDirection="both"
                className="datatable-responsive"
                scrollable
                showGridlines
                lazy
                //panigate
                paginator
                first={lazyParams.first}
                rows={lazyParams.limit}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20, 50, 100]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{first} - {last} of {totalRecords}"
                onPage={onPage}
              >
                <Column
                  field="refer_code"
                  header="Id"
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                  body={(rowData) => <span>{rowData.user?.refer_code}</span>}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  style={{ flexGrow: 1, flexBasis: "280px" }}
                  body={(rowData) => <span>{rowData.user?.email || ""}</span>}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "150px" }}
                  field="amount"
                  header="Amount"
                  body={(rowData) => renderAmount(rowData?.amount || 0)}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "120px" }}
                  field="stock"
                  header="Stock"
                  body={(rowData) => rowData?.stock?.toUpperCase()}
                ></Column>
                <Column
                  style={{ flexGrow: 1, flexBasis: "200px" }}
                  field="createdAt"
                  header="Created At"
                  body={(rowData) => formatLocalTime(rowData.createdAt)}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
