export enum LEVEL_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  CANCEL = "cancel",
}

export enum PARTICIPANT_TYPE {
  TASK = "task",
  CAMPAIGN = "campaign",
}

export enum PARTICIPANT_STATUS {
  COMPLETED = "completed",
  PENDING = "pending",
  ERROR = "error",
}

export enum COMMON_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum MENU_TYPE {
  MENU = "menu",
  SUBMENU = "submenu",
  TAB = "tab",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_TYPE_VIEW {
  EXTERNAL = "external",
  INTERNAL = "internal",
  WEBVIEW = "webview",
}

export enum CATEGORY_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum ADS_BANNER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}
