import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useEffect } from "react";
import { useState } from "react";
import UserService from "services/users";
import { useImperativeHandle } from "react";
import { showToast } from "utils/common";

const clients = [
  "notifications.read",
  "notifications.create",
  "notifications.update",

  "advertisements.read",
  "advertisements.create",
  "advertisements.update",

  "events.create",
  "events.read",
  "events.update",
  "events.delete",
  'events.trust',
  'events.ads_trust',

  "events--school.create",
  "events--school.read",
  "events--school.update",
  "events--school.delete",

  "events--pool.create",
  "events--pool.read",
  "events--pool.update",
  "events--pool.delete",

  "events--hospital.create",
  "events--hospital.read",
  "events--hospital.update",
  "events--hospital.delete",

  "events--charity.create",
  "events--charity.read",
  "events--charity.update",
  "events--charity.delete",

  'projects.trust',
  'projects.ads_trust',
];

const admins = [
  "users.create",
  "users.read",
  "users.update",
  "users.delete",

  "kycs.create",
  "kycs.read",
  "kycs.update",
  "kycs.delete",

  "transactions.create",
  "transactions.read",
  "transactions.update",
  "transactions.delete",

  "cryptos.create",
  "cryptos.read",
  "cryptos.update",
  "cryptos.delete",

  "products.create",
  "products.read",
  "products.update",
  "products.delete",

  "allotments.create",
  "allotments.read",
  "allotments.update",
  "allotments.delete",

  "systems.create",
  "systems.read",
  "systems.update",
  "systems.delete",

  "projects.create",
  "projects.read",
  "projects.update",
  "projects.delete",
  'projects.trust',
  'projects.ads_trust',

  "payments.create",
  "payments.read",
  "payments.update",
  "payments.delete",

  "tasks.create",
  "tasks.read",
  "tasks.update",
  "tasks.delete",

  "notifications.create",
  "notifications.read",
  "notifications.update",
  "notifications.delete",

  "advertisements.create",
  "advertisements.read",
  "advertisements.update",
  "advertisements.delete",

  "your-estock.create",
  "your-estock.read",
  "your-estock.update",
  "your-estock.delete",

  "events.create",
  "events.read",
  "events.update",
  "events.delete",
  'events.trust',
  'events.ads_trust',

  "levels.create",
  "levels.read",
  "levels.update",
  "levels.delete",

  "campaigns.create",
  "campaigns.read",
  "campaigns.update",
  "campaigns.delete",

  "supports.create",
  "supports.read",
  "supports.update",
  "supports.delete",
];

const Roles = (props, ref) => {
  const { user, toast, onCancel, setLoading } = props;

  const [detail, setDetail] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (user)
      UserService.getUserById({
        params: {
          id: user._id,
        },
      }).then((res: any) => {
        if (res.user) {
          if (res.user.role === "ADMIN") setData(admins);
          else setData(clients);
          setDetail(res.user);
          setPermissions(res.user.permissions || {});
        }
      });
  }, [user]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitHandler();
    },
  }));

  const handleSelect = (e: any, p: string) => {
    setPermissions({
      ...permissions,
      [p]: e.checked,
    });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      await UserService.updateUser({
        params: {
          id: detail._id,
        },
        body: {
          ...detail,
          permissions,
        },
      });
      onCancel();
      showToast(toast, "success", "User save!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div>
      <Card header={<h5>User : {user.email}</h5>}>
        <DataTable
          value={data}
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          showGridlines
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="action"
            header="Action"
            body={(rowData) => rowData}
          ></Column>
          <Column
            field="access"
            header="Access"
            body={(rowData) => (
              <Checkbox
                inputId="binary"
                checked={permissions && permissions[rowData]}
                onChange={(e) => handleSelect(e, rowData)}
              />
            )}
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default forwardRef(Roles);
