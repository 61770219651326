/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import KycService from "services/kycs";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, objects } = props;

  const emptyData = {
    status: "pending_verified",
    note: "",
    type: "",
    front: "",
    back: "",
    profile_VDB: "",
    appStore_CHPlay: "",
    video_intro: "",
    img_verifed: "",
    object: "",
    idKyc : ""
  };

  const optionStatus = [
    {
      label: "Pending Verified",
      value: "pending_verified",
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Declined Verified",
      value: "declined_verified",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending_verified") {
      setReadOnly(true);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await KycService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={optionStatus}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
          disabled={readOnly}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <InputText id="type" value={details.type} readOnly />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Object</label>
        <Dropdown
          value={details.object}
          options={objects}
          optionLabel="name"
          optionValue="id"
          readOnly
          disabled={true}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">ID Number</label>
        <InputText id="idKyc" value={details.idKyc} readOnly />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Note</label>
        <InputTextarea
          id="note"
          value={details.note}
          onChange={(e) => onInputChange(e, "note")}
          autoResize
          rows={3}
        />
      </div>
      {details.front && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Front:</label>
          <br></br>
          <Image src={details.front} width="150" height="150" preview />
        </div>
      )}
      {details.back && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Back:</label>
          <br></br>
          <Image src={details.back} width="150" height="150" preview />
        </div>
      )}
      {details.profile_VDB && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Profile VDB:</label>
          <br></br>
          <Image src={details.profile_VDB} width="150" height="150" preview />
        </div>
      )}
      {details.appStore_CHPlay && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Identity verification photo on Vdiarybook:</label>
          <br></br>
          <Image
            src={details.appStore_CHPlay}
            width="150"
            height="150"
            preview
          />
        </div>
      )}
      {details.img_verifed && (
        <div className="col-12 md:col-6">
          <label htmlFor="description">Invoice:</label>
          <br></br>
          <Image src={details.img_verifed} width="150" height="150" preview />
        </div>
      )}
      {details.video_intro && (
        <div className="col-12 md:col-6">
          <label htmlFor="description">Video:</label>
          <video
            src={data.video_intro}
            width="100%"
            height="auto%"
            autoPlay
            loop
          ></video>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
