import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS } from "utils/enum";

interface IProps {
  onChange?: any;
  data?: any;
  errMessage?: string;
  [key: string]: any;
  options: any;
  status?: any;
  rowData?: any;
}

const StatusEditorCell = ({
  className,
  ref,
  label,
  disabled = false,
  value = null,
  required = false,
  placeholder = "",
  defaultValue = null,
  haveData,
  onChange = () => {},
  options,
  status = COMMON_STATUS,
  rowData,
  ...props
}: IProps) => {
  return (
    <Dropdown
      value={value}
      options={Object.keys(status).map((k) => ({
        label: k,
        value: status[k],
      }))}
      onChange={(e: any) => onChange(options, rowData, e.target.value)}
      optionLabel="label"
      placeholder="Status"
      className={"w-10rem m-1"}
      required
    />
  );
};

export default StatusEditorCell;
