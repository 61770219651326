/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import Editor from "./components/Editor";
import ImportPdf from "./components/ImportPdf";

const View = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>User Agreement and Privacy Policy</h4>
          </div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="Write">
              <Editor />
            </TabPanel>
            <TabPanel header="Import Pdf">
              <ImportPdf />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
