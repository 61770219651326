import { useEffect, useRef, useState } from "react";
import { Button, Toast } from "primereact";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import VBanner from "components/v-banner";

const defaultState = {
  type: "slide" || "youtube" || "facebook",
  link: "",
  images: [],
};

const Banners = () => {
  const toast = useRef(null);

  const [state, setState] = useState(defaultState);

  const onChange = (value, name) => {
    let _banner = { ...state };
    _banner[`${name}`] = value;
    setState(_banner);
  };

  const [loading, setLoading] = useState(false);
  const [isHave, setIsHave] = useState(false);
  //   const [slide, setSlide] = useState<any[]>(defaultState.slide);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "banner",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          setIsHave(true);
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
        } else {
          setIsHave(false);
        }
      }
    } catch (error) {}
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!isHave) {
        await SettingService.createSetting({
          body: {
            name: "banner",
            value: state,
          },
        });
      } else {
        await SettingService.updateSetting({
          body: {
            name: "banner",
            value: state,
          },
        });
      }
      await getData();
      showToast(toast, "success", "Success!");
      setLoading(false);
    } catch (error) {}
  };

  //   const submitHandler = async (e) => {
  //     try {
  //       setLoading(true);
  //       e.preventDefault();
  //       let data = {
  //         ...state,
  //         slide,
  //       };
  //       if (slide?.length > 0 && slide?.filter((it) => it.file)?.length > 0) {
  //         data = {
  //           ...data,
  //           slide,
  //         };
  //       }
  //       if (!isHave) {
  //         await SettingService.createSetting({
  //           body: {
  //             name: "banner",
  //             value: data,
  //           },
  //         });
  //       } else {
  //         await SettingService.updateSetting({
  //           body: {
  //             name: "banner",
  //             value: data,
  //           },
  //         });
  //       }
  //       await getData();
  //       showToast(toast, "success", "Success!");
  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       showToast(toast, "error", error.errors);
  //     }
  //   };

  //   const onInputCheckChange = (e, name) => {
  //     const val = e.value || false;
  //     setState({
  //       ...state,
  //       [name]: val,
  //     });
  //   };

  //   const changeHandler = (value, name) => {
  //     setState({
  //       ...state,
  //       [name]: value,
  //     });
  //   };

  //   const moveUp = (index: number) => {
  //     const _slide = slide;
  //     const _before = slide[index - 1];
  //     const _after = slide[index];
  //     _slide[index] = _before;
  //     _slide[index - 1] = _after;
  //     setSlide((_) => [..._slide]);
  //   };

  //   const moveDown = (index: number) => {
  //     const _slide = slide;
  //     const _before = slide[index + 1];
  //     const _after = slide[index];
  //     _slide[index] = _before;
  //     _slide[index + 1] = _after;
  //     setSlide((_) => [..._slide]);
  //   };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <VBanner data={state} onChange={onChange} />
        <div className="field col-12 flex justify-content-center">
          <Button
            label="Cancel"
            className="p-button-danger mr-2"
            loading={loading}
            onClick={handleCancel}
          />
          <Button loading={loading} label="Submit" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Banners;
