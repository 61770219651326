/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MasterContext } from "contexts/MasterContext";
import { Button, Calendar, InputSwitch, Panel } from "primereact";
import "../styles.scss";
import { nanoid } from "nanoid";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VUserDropdown from "components/v-user";
import ProjectService from "services/projects";

const Details = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const { stocks } = useContext(MasterContext);

  const emptyData = {
    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
    },
    name: "",
    symbol: "",
    logo: "",
    total_supply: 0,
    contract: "",
    info: {
      tokenomics: [],
      contract: "",
      url: "",
      description: "",
      tabs: [],
      banner: "",
    },
    cal: {
      stock_price: 0,
      stock_to_buy: "",
      min: 0,
      max: 0,
      amount_to_sale: 0,
    },
    keep_time: {
      ido_open: null,
      ido_close: null,
    },
    status: "upcoming",
    request_mainet: {
      status: "pre-mainet",
      enable: "enable",
    },
    user_id: "",

    is_holding_balance: true,
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        user_id: data.user_id,
        keep_time: {
          ido_close: new Date(data.keep_time?.ido_close),
          ido_open: new Date(data.keep_time?.ido_open),
        },
      });
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const handleAddTokenmics = () => {
    setDetails((prev) => ({
      ...prev,
      info: {
        ...prev["info"],
        tokenomics: [
          ...prev.info.tokenomics,
          { index: nanoid(), name: 0, percent: 0 },
        ],
      },
    }));
  };

  const handleSubTokenmics = () => {
    setDetails((prev) => ({
      ...prev,
      info: {
        ...prev["info"],
        tokenomics: prev.info.tokenomics.slice(
          0,
          prev.info.tokenomics.length - 1
        ),
      },
    }));
  };

  const handleChangeTokenmics = (index) => (name, value) => {
    let _tokenomics = details.info.tokenomics.map((p) =>
      p.index === index
        ? {
            ...p,
            [name]: value,
          }
        : p
    );
    setDetails((prev) => ({
      ...prev,
      info: {
        ...prev["info"],
        tokenomics: _tokenomics,
      },
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await ProjectService.editProject({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await ProjectService.createProject({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="user">User</label>
            <VUserDropdown
              value={details.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="website">Symbol</label>
            <InputText
              id="symbol"
              value={details.symbol}
              onChange={(e) => onChange(e.target.value, "symbol")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="contract">Contract</label>
            <InputText
              id="contract"
              value={details.contract}
              onChange={(e) => onChange(e.target.value, "contract")}
              required
            />
          </div>

          <div className="field col-12">
            <label htmlFor="description">Description</label>
            <InputText
              id="description"
              value={details.info.description}
              onChange={(e) => onChange(e.target.value, "description", "info")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="url">Url</label>
            <InputText
              id="url"
              value={details.info.url}
              onChange={(e) => onChange(e.target.value, "url", "info")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="tabs">Tabs</label>
            <InputText
              id="tabs"
              value={details.info.tabs}
              onChange={(e) => onChange(e.target.value, "tabs", "info")}
              placeholder="binances,houbi"
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={details.status}
              options={[
                {
                  label: "init",
                  value: "init",
                },
                {
                  label: "upcoming",
                  value: "upcoming",
                },
                {
                  label: "opening",
                  value: "opening",
                },
                {
                  label: "closed",
                  value: "closed",
                },
              ]}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              placeholder="Select status"
            />
          </div>
          <div className="field col-12  md:col-6">
            <label htmlFor="status">Mainet : </label>
            <Dropdown
              value={details.request_mainet?.enable}
              options={[
                {
                  label: "enable",
                  value: "enable",
                },
                {
                  label: "disable",
                  value: "disable",
                },
              ]}
              onChange={(e) => onChange(e.value, "enable", "request_mainet")}
              optionLabel="label"
              placeholder="Request mainet"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="start_date">Start Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={
                details.keep_time.ido_open
                  ? new Date(details.keep_time.ido_open)
                  : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "ido_open", "keep_time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="end_date">End Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={
                details.keep_time.ido_close
                  ? new Date(details.keep_time.ido_close)
                  : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "ido_close", "keep_time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>

          <div className="field col-6 md:col-12">
            <label htmlFor="is_enable_claim">Holding Balance</label>
            <br />
            <InputSwitch
              checked={details.is_holding_balance}
              onChange={(e) => onChange(e.value, "is_holding_balance")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Information" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12">
            <label htmlFor="amountToSale">Amount To Sale</label>
            <InputNumber
              id="amountToSale"
              value={details.cal.amount_to_sale}
              onChange={(e) => onChange(e.value, "amount_to_sale", "cal")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="stock_to_buy">Stock To Buy</label>
            <Dropdown
              value={data.cal.stock_to_buy}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock_to_buy", "cal")}
              optionLabel="fullName"
              filterBy="fullName"
              optionValue="symbol"
              filter
              showClear
              placeholder="Select stock"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="stock_price">Stock price</label>
            <InputNumber
              id="stock_price"
              value={details.cal.stock_price}
              onChange={(e) => onChange(e.value, "stock_price", "cal")}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="min">Min sale</label>
            <InputNumber
              id="min"
              value={details.cal.min}
              onChange={(e) => onChange(e.value, "min", "cal")}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="max">Max sale</label>
            <InputNumber
              id="max"
              value={details.cal.max}
              onChange={(e) => onChange(e.value, "max", "cal")}
              required
            />
          </div>
        </div>
      </Panel>

      <Panel header="Tokenomics" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          {details.info.tokenomics.map((profit) => {
            return (
              <>
                <div className="field col-12 md:col-6" key={profit.index}>
                  <label htmlFor="name">Name</label>
                  <InputText
                    id="name"
                    value={profit.name}
                    onChange={(e) =>
                      handleChangeTokenmics(profit.index)(
                        "name",
                        e.target.value
                      )
                    }
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="percent">Percent</label>
                  <InputNumber
                    id="percent"
                    value={profit.percent}
                    onChange={(e) =>
                      handleChangeTokenmics(profit.index)("percent", e.value)
                    }
                    required
                    prefix="%"
                  />
                </div>

                <hr />
              </>
            );
          })}
          <div className="col-12">
            <Button
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success"
              aria-label="Search"
              onClick={handleAddTokenmics}
            />
            <Button
              icon="bx bxs-minus-circle"
              className="p-button-rounded p-button-danger"
              aria-label="Cancel"
              onClick={handleSubTokenmics}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Images" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-6">
            <VUpload
              label="Logo"
              urlFile={details.logo}
              setUrlFile={(file) => onChange(file, "logo")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Banner"
              urlFile={details.info.banner}
              setUrlFile={(file) => onChange(file, "banner", "info")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <VReward
          reward={details.reward}
          setReward={(reward) => onChange(reward, "reward")}
        />
      </Panel>
    </>
  );
};

export default forwardRef(Details);
