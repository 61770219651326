import React, { useEffect, useRef, useState } from "react";
import { Button, InputText, Toast } from "primereact";
import SettingService from "services/settings";
import { nanoid } from "nanoid";
import { showToast } from "utils/common";

const View = () => {
  const toast = useRef(null);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setState((state) => [...state, { index: nanoid() }]);
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };

  const getData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "groups",
        },
      });
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] ? setting["value"] : [];
          setState(value);
        }
      }
    } catch (error) {}
  };

  const handleSubIndex = (index) => {
    const newState = [...state].filter((i) => i.index !== index);
    setState(newState);
  };

  const submitHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await SettingService.updateSetting({
        body: {
          name: "groups",
          value: state,
        },
      });
      await getData();
      showToast(toast, "success", "Update dsuccess!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  // const onInputCheckChange = (e, name, index) => {
  //   const val = e.value || false;
  //   const _state = [...state].map((i) =>
  //     i.index === index
  //       ? {
  //           ...i,
  //           [name]: val,
  //         }
  //       : i
  //   );

  //   setState(_state);
  // };

  const changeHandler = (value, name, index) => {
    const arr = state.map((i) =>
      i.index === index
        ? {
            ...i,
            [name]: value,
          }
        : i
    );
    setState(arr);
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Groups</h4>
          </div>
          {state.map((item, index) => {
            return (
              <div className="grid" key={index}>
                <div className="field col-12 md:col-3">
                  <span className="p-float-label">
                    <InputText
                      id="key"
                      className="w-full"
                      value={item.key}
                      onChange={(e) =>
                        changeHandler(e.target.value, "key", item.index)
                      }
                    />
                    <label htmlFor="id">Key</label>
                  </span>
                </div>

                <div className="field col-12 md:col-3">
                  <span className="p-float-label">
                    <InputText
                      id="name"
                      className="w-full"
                      value={item.name}
                      onChange={(e) =>
                        changeHandler(e.target.value, "name", item.index)
                      }
                    />
                    <label htmlFor="id">Name</label>
                  </span>
                </div>

                {/* <div
                  className="field col-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <br />
                  <InputSwitch
                    checked={item.enable}
                    onChange={(e) =>
                      onInputCheckChange(e, "enable", item.index)
                    }
                  />
                </div> */}
                <div className="field col-12 md:col-2">
                  <Button
                    icon="pi pi-times"
                    className="p-button-rounded p-button-danger"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex(item.index)}
                  />
                </div>
              </div>
            );
          })}
          <Button
            onClick={handleAdd}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3"
            aria-label="Search"
          />
          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={submitHandler} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
