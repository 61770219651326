/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import KycService from "services/kycs";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import { MasterContext } from "contexts/MasterContext";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, objects } = props;
  const { groups } = useContext(MasterContext);

  const emptyData = {
    status: "pending_verified",
    note: "",
    type: "",
    front: "",
    back: "",
    profile_VDB: "",
    appStore_CHPlay: "",
    object: "",
    idKyc: "",
    user: null,
  };

  const optionStatus = [
    {
      label: "Pending Kyc",
      value: "pending_kyc",
    },
    {
      label: "Kyc",
      value: "kyc",
    },
    {
      label: "Declined",
      value: "declined",
    },
  ];

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending_kyc") {
      setReadOnly(true);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await KycService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={optionStatus}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
          disabled={readOnly}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Type</label>
        <InputText id="type" value={details.type} readOnly />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Object</label>
        <Dropdown
          value={details?.user?.object_type}
          options={objects}
          optionLabel="name"
          optionValue="id"
          readOnly
          disabled={true}
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Group</label>
        <Dropdown
          value={details.user?.group}
          options={groups}
          optionLabel="name"
          optionValue="key"
          readOnly
          disabled={true}
        />
      </div>

      <div className="field col-12 ">
        <label htmlFor="description">ID Number</label>
        <InputText
          id="idKyc"
          value={details.idKyc}
          onChange={(e) => onInputChange(e, "idKyc")}
        />
      </div>

      <div className="field col-12">
        <label htmlFor="description">Note</label>
        <InputTextarea
          id="note"
          value={details.note}
          onChange={(e) => onInputChange(e, "note")}
          autoResize
          rows={3}
        />
      </div>
      {details.front && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Front:</label>
          <br></br>
          <Image src={details.front} width="150" height="150" preview />
        </div>
      )}
      {details.back && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Back:</label>
          <br></br>
          <Image src={details.back} width="150" height="150" preview />
        </div>
      )}
      {details.profile_VDB && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">Profile VDB:</label>
          <br></br>
          <Image src={details.profile_VDB} width="150" height="150" preview />
        </div>
      )}
      {details.appStore_CHPlay && (
        <div className="col-12 md:col-4">
          <label htmlFor="description">
            Identity verification photo on Vdiarybook:
          </label>
          <br></br>
          <Image
            src={details.appStore_CHPlay}
            width="150"
            height="150"
            preview
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
