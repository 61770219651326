/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Service from "services/categories";
import ServiceGroups from "services/categories-groups";
import Detail from "./components/Detail";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact/dropdown";
import { TreeTable } from "primereact/treetable";
import { Image } from "primereact/image";

const Crud = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    group: null,
    parent_id: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 99999,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
    group: null,
  });
  // const [totalRecords, setTotalRecords] = useState(0);
  const [categories, setCategories] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);
  const [treeData, setTreeData] = useState([]);
  const refDialogAttribute = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    loadCategoryGroup();
    getCategories();
  }, []);

  const getCategories = async () => {
    const res: any = await Service.getAll();
    setCategories(res);
  };

  const loadCategoryGroup = () => {
    ServiceGroups.getAll().then((res: any) => {
      if (res) {
        setGroups(res);
      }
    });
  };

  const loadData = async () => {
    try {
      const res: any = await Service.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: lazyParams.rows,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setAllData(res.docs);
      } else {
        setData([]);
        setAllData([]);
      }
    } catch (error) {
      setData([]);
    }
  };

  useEffect(() => {
    if (data) {
      const mapAll = allData.map((e: any) => ({
        ...e,
        id: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        id: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter((e: any) => e.parent_id == null);
      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, allData]);

  const getItems = (mapList, parentId) => {
    let items = mapList.filter((e: any) => e.parent_id === parentId);
    items.forEach((el: any) => {
      const items = getItems(mapList, el._id);
      if (items && items != null && items.length > 0) {
        el.items = items;
        el.children = items;
      }
    });
    return items;
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  // const editAttribute = (data) => {
  //   setDetail({ ...data });
  //   refDialogAttribute.current.show();
  // };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const editMenuCategory = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await Service.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Category Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editMenuCategory(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const getByParent = (parent) => {
    if (categories) {
      let mapRes = categories.filter((e) => e.parent_id === parent);
      mapRes.forEach((e) => {
        if (categories.find((el) => el.parent_id === e._id)) {
          e.leaf = false;
        } else {
          e.leaf = true;
        }
      });
      return mapRes;
    } else {
      return [];
    }
  };

  const onExpand = (event) => {
    let lazyNode = { ...event.node };

    lazyNode.children = getByParent(event.node._id);

    let _nodes = data.map((e) => {
      if (e.key === event.node.key) {
        e = lazyNode;
      } else {
        e.children = e.children ? buildNode(e.children, lazyNode) : null;
      }
      return e;
    });

    setData(_nodes);
  };

  const buildNode = (children, lazyNode) => {
    let _nodes = children.map((chil) => {
      if (chil.key === lazyNode.key) {
        chil = lazyNode;
        return chil;
      }
      if (chil.children && chil.children.length > 0) {
        chil.children = buildNode(chil.children, lazyNode);
      }
      return chil;
    });

    return _nodes;
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Parent</h5>
      <div className="grid my-2 align-items-center">
        <div className="mr-2">
          <Dropdown
            value={globalFilter.parent_id}
            options={categories}
            optionLabel="name"
            optionValue="_id"
            onChange={(e) => onSearch("parent_id", e.value)}
            showClear
            filter
            placeholder="Parent"
          />
        </div>
        <Dropdown
          value={globalFilter.group}
          options={groups}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onSearch("group", e.value)}
          showClear
          filter
          placeholder="Group"
        />
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
      </div>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            resizableColumns
            columnResizeMode="expand"
            scrollable
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              field="key"
              header="Key"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              expander
              body={(rowData) => <span>{rowData.key}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "50px" }}
              header="Icon"
              body={(rowData) => {
                return (
                  <Image
                    src={rowData.icon}
                    alt={rowData.name}
                    width="50"
                    height="50"
                    preview
                  />
                );
              }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData.name}</span>}
            ></Column>
            <Column
              field="description"
              header="Description"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData.description}</span>}
            ></Column>
            <Column
              field="order"
              header="Order"
              style={{ flexGrow: 1, width: "8%" }}
              body={(rowData) => <span>{rowData.order}</span>}
            ></Column>
            <Column
              field="status"
              header="Status"
              style={{ flexGrow: 1, width: "10%" }}
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status?.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "200px" }}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Detail"
            onSubmit={onSumitDialogDetail}
          >
            <Detail
              ref={refDetail}
              data={detail}
              groups={groups}
              toast={toast}
              categories={categories}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
