import { Dropdown, InputText } from "primereact";
import React from "react";
import SlideUpload from "./components/SlideUpload";

const BANNER_TYPE = [
  {
    label: "Slide",
    value: "slide",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
];

type IProps = {
  data?: any;
  onChange?: any;
};

const VBanner = ({ data, onChange }: IProps) => {
  return (
    <div className="grid col-12 w-full">
      <div className="field col-12 w-full">
        <label htmlFor="type">Type </label>
        <Dropdown
          className="w-full"
          value={data?.type}
          options={BANNER_TYPE}
          onChange={(e) => onChange(e.value, "type", "banner")}
          optionLabel="label"
          filter
          placeholder="Select type"
        />
      </div>
      {data?.type === "slide" && (
        <>
          <div className="field col-12">
            <SlideUpload
              slide={data?.images}
              setSlide={(slide) => onChange(slide, "images", "banner")}
            />
          </div>
        </>
      )}
      {(data?.type === "video" || data?.type === "facebook") && (
        <div className="field col-12">
          <label htmlFor="link">Link</label>
          <InputText
            placeholder={data?.type}
            id="banner"
            value={data?.link}
            onChange={(e) => onChange(e.target.value, "link", "banner")}
            required
            autoFocus
          />
        </div>
      )}
    </div>
  );
};

export default VBanner;
