/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MasterContext } from "contexts/MasterContext";
import { Calendar, InputSwitch, MultiSelect, Panel } from "primereact";
import "../styles.scss";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VUserDropdown from "components/v-user";
import CampaignService from "services/campaigns";
import { COMMON_STATUS } from "utils/enum";
import VLocation from "components/v-location";
import Service from "services/categories";
import VBanner from "components/v-banner";

const Details = (props, ref) => {
  const { data, reload, toast, setLoading } = props;
  const { stocks, groups, objects, packages, getPackages } =
    useContext(MasterContext);

  const emptyData = {
    user_id: null,
    stock: "",
    start_date: new Date(),
    end_date: new Date(),
    status: COMMON_STATUS.ENABLE,

    info: {
      name: "",
      video: "",
      website: "",
      banner: "",
      logo: "",
      guide: "",
      img_banner: "",
      img_info: "",
      bg_success: "",
      img_promotion: "",
      img_reward: "",
      attachments: [],
    },

    filter: {
      groups: [],
      objects: [],
      address: {},
      users: [],
      branch: null,
    },
    apply: {
      is_leader: false,
      is_all: true,
    },
    reward: {
      stocks: [],
      vouchers: [],
      codes: [],
      tickets: [],
    },
    apply_packages: [],
    category_id: null,
    banner: {
      type: "slide" || "youtube" || "facebook",
      link: "",
      images: [],
    },
  };

  const [details, setDetails] = useState(emptyData);

  // categories
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const res: any = await Service.getAll();
      if (res) {
        setCategories(res);
      }
    } catch (error) {}
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getPackages();
  }, []);

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CampaignService.editCampaign({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await CampaignService.createCampaign({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="user">Owner</label>
            <VUserDropdown
              value={details.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.info.name}
              onChange={(e) => onChange(e.target.value, "name", "info")}
              required
              autoFocus
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="website">Website</label>
            <InputText
              id="website"
              value={details.info.website}
              onChange={(e) => onChange(e.target.value, "website", "info")}
              required
              autoFocus
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="video">Video</label>
            <InputText
              id="video"
              value={details.info.video}
              onChange={(e) => onChange(e.target.value, "video", "info")}
              required
              autoFocus
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={details.status}
              options={Object.keys(COMMON_STATUS).map((k) => ({
                label: k,
                value: COMMON_STATUS[k],
              }))}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              placeholder="Select status"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="website">Stock </label>
            <Dropdown
              defaultValue={details.stock}
              value={details.stock}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock")}
              optionLabel="fullName"
              filterBy="fullName"
              optionValue="symbol"
              filter
              showClear
              placeholder="Select stock"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="start_date">Start Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Start Date"
              value={
                details.start_date ? new Date(details.start_date) : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "start_date")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="end_date">End Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="End Date"
              value={details.end_date ? new Date(details.end_date) : undefined}
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "end_date")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="status">Apply Packages</label>
            <MultiSelect
              value={details.apply_packages}
              options={packages}
              onChange={(e) => onChange(e.value, "apply_packages")}
              optionLabel="name"
              optionValue="_id"
              placeholder="Select packages"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="category">Category </label>
            <Dropdown
              value={details.category_id}
              options={categories
                ?.filter((a) => a.group === "category_campaign")
                .map((el) => {
                  return {
                    label: el.name,
                    value: el._id,
                  };
                })}
              onChange={(e) => onChange(e.value, "category_id")}
              optionLabel="label"
              filter
              placeholder="Select category"
            />
          </div>
        </div>
      </Panel>

      <Panel header="Banner" toggleable className="my-2">
        <div className="field col-12">
          <VBanner data={details.banner} onChange={onChange} />
        </div>
      </Panel>

      <Panel header="Filter" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Groups</label>
            <MultiSelect
              value={details.filter.groups}
              options={groups}
              onChange={(e) => onChange(e.value, "groups", "filter")}
              optionLabel="name"
              optionValue="key"
              placeholder="Select a group"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Objects</label>
            <MultiSelect
              value={details.filter.objects}
              options={objects}
              onChange={(e) => onChange(e.value, "objects", "filter")}
              optionLabel="name"
              optionValue="id"
              placeholder="Select a object"
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="user">Branch User</label>
            <VUserDropdown
              value={details.filter.branch}
              setValue={(user) => onChange(user, "branch", "filter")}
            />
          </div>
          <div className="field col-12 ">
            <label htmlFor="user">Address</label>
            <VLocation
              address={details.filter.address}
              setAddress={(a) => onChange(a, "address", "filter")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Apply" toggleable className="my-2">
        <div className="grid">
          <div className="field col-6 md:col-6">
            <label htmlFor="allUser">All user</label>
            <br />
            <InputSwitch
              checked={details.apply.is_all}
              onChange={(e) => onChange(e.value, "is_all", "apply")}
            />
          </div>
          <div className="field col-6 md:col-6">
            <label htmlFor="leaders">Is Leaders</label>
            <br />
            <InputSwitch
              checked={details.apply.is_leader}
              onChange={(e) => onChange(e.value, "is_leader", "apply")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Images" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-6">
            <VUpload
              label="Logo"
              urlFile={details.info.logo}
              setUrlFile={(file) => onChange(file, "logo", "info")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <VUpload
              label="Guide"
              urlFile={details.info.guide}
              setUrlFile={(file) => onChange(file, "guide", "info")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <VUpload
              label="Banner"
              urlFile={details.info.img_banner}
              setUrlFile={(file) => onChange(file, "img_banner", "info")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <VUpload
              label="Bg Success"
              urlFile={details.info.bg_success}
              setUrlFile={(file) => onChange(file, "bg_success", "info")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Promotion"
              urlFile={details.info.img_promotion}
              setUrlFile={(file) => onChange(file, "img_promotion", "info")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Reward"
              urlFile={details.info.img_reward}
              setUrlFile={(file) => onChange(file, "img_reward", "info")}
            />
          </div>
        </div>
      </Panel>
      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <VReward
          reward={details.reward}
          setReward={(reward) => onChange(reward, "reward")}
        />
      </Panel>
    </>
  );
};

export default forwardRef(Details);
