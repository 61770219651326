import { request, parseErrorResponse } from "./request";

const search = (req: { body: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/purchases/search`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPurchaseByType = (req: { params: { type: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/purchases/getByType/${req.params.type}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const exportData = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/purchases/exports`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PurchaseService = {
  search,
  getPurchaseByType,
  exportData
};

export default PurchaseService;
