/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { InputText, Panel } from "primereact";
import React, { useEffect, useImperativeHandle, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { v4 as uuidv4 } from "uuid";

const Details = (props, ref) => {
  const { data, toast, reload, setLoading } = props;
  const [details, setDetails] = useState(null);

  const getDefaultData = () => {
    return {
      _id: uuidv4(),
      name: "",
      frontBackground: "",
      backBackground: "",
      logo: "",
    };
  };

  useEffect(() => {
    if (props.details) {
      setDetails(props.details);
    } else {
      setDetails(getDefaultData());
    }
  }, [props.details]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      setLoading(true);
      if (!data) {
        return await SettingService.createSetting({
          body: {
            name: "bank_cards",
            value: [details],
          },
        });
      } else {
        let _data = [];
        if (props.details) {
          _data = data.map((i) => (i._id === details._id ? details : i));
        } else {
          _data = [...data, details];
        }
        await SettingService.updateSetting({
          body: {
            name: "bank_cards",
            value: _data,
          },
        });
      }

      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
      setDetails(null);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  // const handleAddField = () => {
  //   setDetails((prev) => ({
  //     ...prev,
  //     fields: prev.fields?.concat({
  //       label: "",
  //       value: "",
  //     }),
  //   }));
  // };

  // const handleSubField = () => {
  //   setDetails((prev) => ({
  //     ...prev,
  //     fields: prev.fields.slice(0, prev.fields?.length - 1),
  //   }));
  // };

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  // const handleChangeField = (index) => (name, value) => {
  //   let _fields = details.fields.map((p) =>
  //     p.index === index
  //       ? {
  //           ...p,
  //           [name]: value,
  //         }
  //       : p
  //   );
  //   setDetails((prev) => ({
  //     ...prev,
  //     fields: _fields,
  //   }));
  // };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="description" className="font-semibold">
              Bank name
            </label>
            <InputText
              id="name"
              value={details?.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="frontBackground" className="text-center w-full">
              Front background
            </label>
            <VUpload
              urlFile={details?.frontBackground}
              setUrlFile={(e) => onChange(e, "frontBackground")}
              size="450x250"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="backBackground" className="text-center w-full">
              Back background
            </label>
            <VUpload
              urlFile={details?.backBackground}
              setUrlFile={(e) => onChange(e, "backBackground")}
              size="450x250"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="logo" className="text-center w-full">
              Logo
            </label>
            <VUpload
              urlFile={details?.logo}
              setUrlFile={(e) => onChange(e, "logo")}
              size="40x40"
            />
          </div>

          {/* <div className="field col-12 md:col-12">
            <Panel header="User input" toggleable className="my-2">
              {details?.fields?.map((item) => (
                <div key={item?.index} className="grid">
                  <div className="field md:col-6 col-12">
                    <label htmlFor="user">Label</label>
                    <InputText
                      id="label"
                      value={item?.label}
                      onChange={(e) =>
                        handleChangeField(item?.index)("label", e.target.value)
                      }
                      required
                    />
                  </div>
                  <div className="field md:col-6 col-12">
                    <label htmlFor="from">Value</label>
                    <InputText
                      id="value"
                      value={item?.value}
                      onChange={(e) =>
                        handleChangeField(item?.index)("value", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
              ))}

              <div className="col-12">
                <Button
                  icon="bx bxs-add-to-queue"
                  className="p-button-rounded p-button-success"
                  aria-label="Search"
                  onClick={handleAddField}
                />
                <Button
                  icon="bx bxs-minus-circle"
                  className="p-button-rounded p-button-danger"
                  aria-label="Cancel"
                  onClick={handleSubField}
                />
              </div>
            </Panel>
          </div> */}
        </div>
      </Panel>
    </>
  );
};

export default React.forwardRef(Details);
