import { request, parseErrorResponse } from "./request";

const getSettings = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/settings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSettingsByName = (req: { params: { name: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/settings/get-by-name/${req.params.name}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateSetting = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/settings/update-by-name`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createSetting = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/settings/create`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNotifications = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/notifications`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNotificationByID = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/notifications/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createNotification = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/notifications`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateNotification = (req: { params: { id: any }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/notifications/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deletedNotification = (req: { params: { id: any } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/notifications/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/locations`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SettingService = {
  createSetting,
  getSettings,
  updateSetting,
  getSettingsByName,
  getNotifications,
  createNotification,
  updateNotification,
  deletedNotification,
  getNotificationByID,
  getLocations,
};

export default SettingService;
