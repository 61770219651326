import qs from "query-string";
import { parseErrorResponse, request } from "./request";

const search = (req: { query: any }) =>
  new Promise(async (resolve, reject) => {
    try {
      const query = qs.stringify(req.query);
      request()
        .get(`admin/attribute-set/search?${query}`)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const findAll = () =>
  new Promise(async (resolve, reject) => {
    try {
      request()
        .get(`admin/attribute-set`)
        .then((res) => {
          const { data } = res.data;
          if (data) {
            resolve(data);
          } else {
            reject(new Error("Something went error!"));
          }
        })
        .catch(parseErrorResponse)
        .then(reject);
    } catch (error) {}
  });

const AttributeSetService = {
  search,
  findAll,
};

export default AttributeSetService;
