import { Dropdown, InputText } from "primereact";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import LevelService from "services/levels";
import { showToast } from "utils/common";

type IProps = {
  data?: any;
  toast?: any;
  reload?: () => any;
  setLoading?: (value: any) => void;
  onCancel?: () => any;
};

const LevelDetails = (
  { data, toast, reload, setLoading, onCancel }: IProps,
  ref: any
) => {
  const [level, setLevel] = useState(data);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      await LevelService.update({
        params: { id: level._id },
        body: { status_upload: level.status_upload },
      });
      showToast(toast, "success", "Updated");
      ref.current.close();
      reload();
    } catch (error) {}
  };

  const onChangeStatusUpload = (status: string) => {
    setLevel((prev) => ({
      ...prev,
      status_upload: status,
    }));
  };

  const currentLevel = level.campaign.levels?.find(
    (el) => el.id === level.level_id
  );
  const indexLevelApprove = level.campaign.levels?.findIndex(
    (el) => el.id === level.level_id
  );
  const levelApprove = level.campaign.levels?.find((el, index) =>
    indexLevelApprove >= 0
      ? index === indexLevelApprove
      : index === indexLevelApprove + 1
  );

  const renderItemDocs = (label: string, value: any) => {
    return (
      <>
        <div className="field col-12">
          <label htmlFor="total_user">{label}</label>
          <br />
          <div className="grid col-12">
            {(value || []).map((el) => (
              <img
                style={{ maxHeight: "120px", width: "auto" }}
                className="col-3 "
                src={el.src}
                alt={label}
              />
            ))}
          </div>
        </div>
      </>
    );
  };
  const renderItem = (label: string, value: any) => {
    return (
      <>
        <div className="field col-3">
          {label}: {value}
        </div>
      </>
    );
  };

  return (
    <div className="grid">
      <div className="grid col-12">
        <h5 className="field col-12">Current user level</h5>
        <div className="field col-6">
          <label htmlFor="time_lock">Name</label>
          <InputText
            id="id"
            value={currentLevel ? currentLevel.name : "Level 0"}
            required
            disabled
          />
        </div>

        {level.images.length > 0 && renderItemDocs("Images", level.images)}
        {level.videos.length > 0 && renderItemDocs("Videos", level.videos)}
        {level.attachments.length > 0 &&
          renderItemDocs("Attachments", level.attachments)}
        {renderItem("Total user invest", level.total_user_invest)}
        {renderItem("Percent leader", level.percent_leader)}
        {renderItem("Percent province", level.percent_province)}
        {renderItem("Qty Branch Invest", level.qty_user_revenue)}
        {renderItem("Amount Branch Invest", level.amount_user_revenue)}
      </div>
      <div className="field col-12">
        <label htmlFor="desc">Status upload</label>
        <Dropdown
          value={
            level.status_upload === "init" ? "approved" : level.status_upload
          }
          options={[
            {
              label: "Pending",
              value: "pending",
            },
            {
              label: "Approved",
              value: "approved",
            },
          ]}
          placeholder="Status upload"
          onChange={(e) => onChangeStatusUpload(e.value)}
        />
      </div>
      <hr className="field col-12 border-bottom" />
      <h5 className="field col-12">Level approve</h5>
      <div className="field col-6">
        <label htmlFor="time_lock">Name</label>
        <InputText id="id" value={levelApprove?.name} required disabled />
      </div>
      <div className="grid col-12">
        {renderItem("Total user invest", levelApprove?.total_user_invest)}
        {renderItem("Percent leader", levelApprove?.percent_leader)}
        {renderItem("Percent province", levelApprove?.percent_province)}
        {renderItem("Images", levelApprove?.amount_image)}
        {renderItem("Videos", levelApprove?.amount_video)}
        {renderItem("Attachments", levelApprove?.amount_attachment)}
        {renderItem("Qty Branch Invest", levelApprove?.qty_user_revenue)}
        {renderItem("Amount Branch Invest", levelApprove?.amount_user_revenue)}
      </div>
    </div>
  );
};

export default forwardRef(LevelDetails);
