import { Button, InputText, OverlayPanel } from "primereact";
import { useRef } from "react";
import { formatNumber } from "utils/common";

const View = ({
  fieldFrom,
  fieldTo,
  fieldFromText,
  fieldToText,
  onChangeFieldFrom,
  onChangeFieldTo,
  onChangeFilter,
  filter,
  title,
  customClassName = "",
}) => {
  const op = useRef(null);

  return (
    <span
      className={`block m-1 p-input-icon-left ${
        customClassName ? customClassName : "w-15rem"
      } `}
      onClick={(e) => {
        op.current.toggle(e);
      }}
    >
      <i className="pi pi-search" />
      <InputText
        type="text"
        value={`${formatNumber(filter[fieldFromText])} - ${formatNumber(
          filter[fieldToText]
        )}`}
        placeholder="Amount..."
        readOnly
        className="w-full"
      />
      <OverlayPanel
        ref={op}
        className="w-[200px]"
        onClick={(e) => e.preventDefault()}
      >
        <div
          className="flex flex-column gap-y-4 w-[200px]"
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-base font-bold">{title}</p>
          <div className="flex align-items-center">
            <p className="text-sm mb-0 w-3rem font-medium">From: </p>
            <InputText
              type="number"
              value={!!fieldFrom ? fieldFrom : ""}
              onChange={(e) => onChangeFieldFrom(+e.target.value)}
              onWheel={(e) => e.currentTarget.blur()}
              placeholder="0"
            />
          </div>
          <div className="flex align-items-center mt-2">
            <p className="text-sm mb-0 w-3rem font-medium">To: </p>
            <InputText
              type="number"
              value={!!fieldTo ? fieldTo : ""}
              onChange={(e) => onChangeFieldTo(+e.target.value)}
              onWheel={(e) => e.currentTarget.blur()}
              placeholder="0"
            />
          </div>
          <Button
            className="mt-2 flex align-items-center justify-content-center"
            onClick={(e) => {
              op.current.toggle(e);
              !!fieldFrom && onChangeFieldFrom(0);
              !!fieldTo && onChangeFieldTo(0);
              onChangeFilter({
                ...filter,
                [fieldFromText]: fieldFrom,
                [fieldToText]: fieldTo,
              });
            }}
          >
            Submit
          </Button>
        </div>
      </OverlayPanel>
    </span>
  );
};

export default View;
