import { request, parseErrorResponse } from "./request";
import { IRequestData } from "./s3-request";
import qs from "query-string";

const search = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/supports/search?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSupports = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/supports`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const replySupportById = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/supports/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SupportService = {
  search,
  getSupports,
  replySupportById,
};

export default SupportService;
