import VBanner from "components/v-banner";
import {
  Calendar,
  Dropdown,
  InputNumber,
  InputSwitch,
  Panel,
} from "primereact";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AdsBannerService from "services/adsBanner";
import MenuService from "services/menus";
import { showToast } from "utils/common";
import { COMMON_STATUS } from "utils/enum";

type IProps = {
  data?: any;
  toast?: any;
  loading?: boolean;
  setLoading?: any;
  reload?: any;
  onCancel?: any;
};

const ADS_DEFAULT = {
  position: "top" || "bottom" || "center",
  menu_id: "",
  status: COMMON_STATUS.ENABLE,
  banner: {
    type: "slide" || "youtube" || "facebook",
    link: "",
    images: [],
  },
  is_loop: false,
  time_loop: 0,
  time_start_show: 0,
  time_end_show: 0,
  enable_close: false,
  time_enable_close: 0,
  start_date: null,
  end_date: null,
};

const AdsDetails = (
  { data, toast, onCancel, loading = false, setLoading, reload }: IProps,
  ref
) => {
  const [details, setDetails] = useState(ADS_DEFAULT);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data)
      setDetails({
        ...data,
        start_date: new Date(data.start_date),
        end_date: new Date(data.end_date),
      });
  }, [data]);

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await AdsBannerService.update({
          params: { id: data._id },
          body: {
            ...details,
          },
        });
      } else {
        await AdsBannerService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  //   Menus
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    getMenus();
  }, []);
  const getMenus = async () => {
    try {
      const res: any = await MenuService.getAll();
      if (res) {
        setMenus(res);
      } else {
        setMenus([]);
      }
    } catch (error) {
      setMenus([]);
    }
  };

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="user">Menu</label>
        <Dropdown
          value={details.menu_id}
          optionLabel="label"
          options={menus.map((menu) => {
            return {
              label: menu.name,
              value: menu._id,
            };
          })}
          onChange={(e) => onChange(e.value, "menu_id")}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="status">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(COMMON_STATUS).map((k) => ({
            label: k,
            value: COMMON_STATUS[k],
          }))}
          onChange={(e) => onChange(e.value, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="user">Position</label>
        <Dropdown
          value={details.position}
          options={[
            {
              label: "Top",
              value: "top",
            },
            {
              label: "Bottom",
              value: "bottom",
            },
            {
              label: "Center",
              value: "center",
            },
          ]}
          onChange={(e) => onChange(e.value, "position")}
          optionLabel="label"
          placeholder="Select position"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="isLoop">Is loop</label>
        <br />
        <InputSwitch
          checked={details.is_loop}
          onChange={(e) => onChange(e.value, "is_loop")}
        />
        <br />
        {details.is_loop && (
          <>
            <label htmlFor="timeLoop">{`Time loop (s)`}</label>
            <InputNumber
              id="timeLoop"
              value={details.time_loop}
              onChange={(e) => onChange(e.value, "time_loop")}
              required
              autoFocus
            />
          </>
        )}
      </div>
      <div className="field col-6">
        <label htmlFor="enableClose">Enable close</label>
        <br />
        <InputSwitch
          checked={details.enable_close}
          onChange={(e) => onChange(e.value, "enable_close")}
        />
        <br />
        {details.enable_close && (
          <>
            <label htmlFor="timeEnableClose">{`Time enable close (s)`}</label>
            <InputNumber
              id="timeEnableClose"
              value={details.time_enable_close}
              onChange={(e) => onChange(e.value, "time_enable_close")}
              required
              autoFocus
            />
          </>
        )}
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeStart">Time start show</label>
        <InputNumber
          id="timeStartShow"
          value={details.time_start_show}
          onChange={(e) => onChange(e.value, "time_start_show")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">Time end show</label>
        <InputNumber
          id="timeEndShow"
          value={details.time_end_show}
          onChange={(e) => onChange(e.value, "time_end_show")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">Start date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={
            details?.start_date ? new Date(details?.start_date) : undefined
          }
          onChange={(e) => onChange(e.value, "start_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="timeEnd">End date</label>
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={details?.end_date ? new Date(details?.end_date) : undefined}
          onChange={(e) => onChange(e.value, "end_date")}
          showTime
          showSeconds
          showButtonBar
        />
      </div>
      <div className="field col-12">
        <Panel header="Banner" toggleable className="my-2">
          <VBanner data={details.banner} onChange={onChange} />
        </Panel>
      </div>
    </div>
  );
};

export default forwardRef(AdsDetails);
