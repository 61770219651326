/* eslint-disable react-hooks/exhaustive-deps */
import { MultiSelect } from "primereact";

const View = ({ options, value, onChange, className = "" }) => {
  return (
    <MultiSelect
      value={value}
      options={options}
      onChange={(e) => onChange(e)}
      optionLabel="name"
      optionValue="_id"
      placeholder="Select a package"
      filter
      filterPlaceholder="Search package"
      className={className}
    />
  );
};

export default View;
