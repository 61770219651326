/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { formatNumber, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";
import PaymentService from "services/payments";
import { Button } from "primereact";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoadingSaveDetail, statuses } =
    props;

  const emptyData = {
    user_id: { email: "" },
    type: "",
    amount: 0,
    bank: { bank_name: "", account_name: "", account_number: "" },
    content: "",
    note: "",
    proof: "",
    status: "pending",
    address: "",
    network: "",
    amount_received: 0,
    stock: "",
    file: "",
    currency: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [readOnly, setReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: (action) => {
      submit(action);
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data.status && data.status !== "pending") {
      setReadOnly(true);
    }
  }, [data]);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async (action) => {
    try {
      setLoadingSaveDetail(true);
      setLoading(true);
      if (data) {
        if (action === "update") {
          await PaymentService.updatePayment({
            params: {
              id: data._id,
            },
            body: {
              ...details,
            },
          });
        } else {
          await PaymentService.changeStatusPayment({
            params: {
              id: data._id,
            },
            body: {
              ...details,
              type: action,
            },
          });
        }
      }
      setLoadingSaveDetail(false);
      setLoading(true);
      showToast(toast, "success", "Save success!");
      onCancel();
      reload();
    } catch (error) {
      setLoadingSaveDetail(false);
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      {!readOnly && (
        <>
          <div className="field col-12 flex">
            <Button
              label="Accept"
              icon="pi pi-check"
              loading={loading}
              onClick={(_) => submit("accept")}
            />
            <Button
              label="Decline"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loading}
              onClick={(_) => submit("decline")}
            />
          </div>
        </>
      )}
      {details.proof && (
        <div className="col-12 flex justify-content-center">
          <Image src={details.proof} height="300" preview />
        </div>
      )}
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={statuses}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="name"
          placeholder="Select status"
          disabled={true}
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">User</label>
        <InputText id="email" value={details.user_id.email} readOnly />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="content">Content</label>
        <InputText id="content" value={`${details.content}`} readOnly />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Amount sender</label>
        <InputText
          id="idKyc"
          value={`${formatNumber(details.amount)} ${details.currency}`}
          readOnly
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Amount receive</label>
        <InputText
          id="idKyc"
          value={`${formatNumber(details.amount_received)} ${details.stock}`}
          readOnly
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="type">Type</label>
        <InputText id="type" value={details.type} readOnly />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="network">Network</label>
        <InputText id="network" value={details.network} readOnly />
      </div>
      {details.bank && (
        <>
          <div className="field col-12 md:col-4">
            <label htmlFor="bank_name">Bank</label>
            <InputText id="bank_name" value={details.bank.bank_name} readOnly />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="account_name">Account name</label>
            <InputText
              id="account_name"
              value={details.bank.account_name}
              readOnly
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="account_number">Account number</label>
            <InputText
              id="account_number"
              value={details.bank.account_number}
              readOnly
            />
          </div>
        </>
      )}
      <div className="field col-12">
        <label htmlFor="description">Note</label>
        <InputTextarea
          id="note"
          value={details.note}
          onChange={(e) => onInputChange(e, "note")}
          autoResize
          rows={3}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
