/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import ProductService from "services/products";
import debounce from "lodash.debounce";
import {
  FileUpload,
  Toolbar,
  InputText,
  MultiSelect,
  Calendar,
  Panel,
} from "primereact";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Product from "./Detail";
import { showToast } from "utils/common";
import { renderAmount } from "utils/render";
import { useHistory } from "react-router-dom";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Opening", value: "enable" },
  { name: "Closed", value: "disable" },
];

const METHOD = [
  { name: "excel", value: "excel" },
  { name: "sync", value: "sync" },
  { name: "handmade", value: "handmade" },
];

const View = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detail, setDetail] = useState(null);
  const toast = useRef(null);
  const history = useHistory();

  const refDetail = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const dt = useRef(null);

  const [globalFilter, setGlobalFilter] = useState({
    status: STATUS.map((s) => s.value),
    search: null,
    start: null,
    end: null,
    method: METHOD.map((s) => s.value),
  });

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await ProductService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res) {
        setData(res.content);
        setTotalRecords(res.total);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };

  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };

  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await ProductService.deleteProducts({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Product Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const editProduct = async (data) => {
    try {
      setDetail({ ...data });
      refDialogDetail.current.show();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block col-12 md:col-4 p-input-icon-left">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="Start Date"
            value={globalFilter.start}
            onChange={(e: any) => onSearch("start", e.value)}
            showTime
            showSeconds
            showButtonBar
          />{" "}
          <Calendar
            className="col-6 md:col-4"
            id="time24"
            placeholder="End Date"
            value={globalFilter.end}
            onChange={(e: any) => onSearch("end", e.value)}
            showTime
            showSeconds
            showButtonBar
          />
          <div className="col-12 md:col-6">
            <MultiSelect
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              maxSelectedLabels={3}
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-6">
            <MultiSelect
              value={globalFilter.method}
              options={METHOD}
              onChange={(e: any) => onSearch("method", e.value)}
              optionLabel="name"
              placeholder="Method"
              maxSelectedLabels={3}
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12 ">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              className="z-506"
              body={(rowData) => (
                <div className="actions">
                  <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-sm p-button-success mr-2"
                    onClick={() => editProduct(rowData)}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-sm p-button-warning"
                    onClick={() => confirmDelete(rowData)}
                  />
                </div>
              )}
            ></Column>
            <Column
              field="code"
              header="Code"
              style={{ flexGrow: 1, flexBasis: "70px" }}
              body={(rowData) => {
                if (rowData.method !== "sync" && rowData.type !== "code")
                  return (
                    <div className="actions">
                      <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button-sm p-button-success mr-2"
                        onClick={(_) =>
                          history.push(`/products/codes/${rowData._id}`)
                        }
                      />
                    </div>
                  );
              }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "200px" }}
              body={(rowData) => <span>{rowData.name || ""}</span>}
            ></Column>
            <Column
              field="thumbnail"
              header="Thumnbnail"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) => (
                <img
                  src={rowData.thumbnail}
                  alt={rowData.thumbnail}
                  width="50"
                />
              )}
            ></Column>
            <Column
              field="price"
              header="Price"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              sortable
              body={(rowData) => renderAmount(rowData.price)}
            ></Column>
            <Column
              field="type"
              header="Type"
              style={{ flexGrow: 1, flexBasis: "80px" }}
              body={(rowData) => <span>{rowData.type || ""}</span>}
            ></Column>
            <Column
              field="token"
              header="Token"
              style={{ flexGrow: 1, flexBasis: "70px" }}
              body={(rowData) => <span>{rowData.token || ""}</span>}
            ></Column>
            <Column
              field="priceToken"
              header="Price Token"
              style={{ flexGrow: 1, flexBasis: "130px" }}
              sortable
              body={(rowData) => renderAmount(rowData.price_token)}
            ></Column>
            <Column
              field="amount"
              header="Amount Sold"
              style={{ flexGrow: 1, flexBasis: "130px" }}
              sortable
              body={(rowData) => renderAmount(rowData.amount_sold)}
            ></Column>
            <Column
              field="url"
              header="Url"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => (
                <a href={rowData.url} target="_blank" rel="noreferrer">
                  {String(rowData.url || "").slice(0, 30)}
                </a>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              sortable
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Product"
            onSubmit={onSumitDialogDetail}
          >
            <Product
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

export default View;
