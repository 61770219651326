/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { nanoid } from "nanoid";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";

import Settings from "services/settings";

const Details = (props, ref) => {
  const { setting, data, reload, toast, onCancle, setLoading } = props;

  const emptyData = {
    id: nanoid(),
    title: "",
    fields: [],
    attachments: {
      enable: false,
      guide: null,
    },
  };

  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
  }, [data]);

  const onInputChange = (e, name, index?) => {
    if (name === "title") {
      const val = (e.target && e.target.value) || "";
      let _details = { ...details };
      _details[`${name}`] = val;
      setDetails(_details);
    } else {
      setDetails({
        ...details,
        fields: details.fields.map((el: any, idx: number) =>
          idx === index ? { ...el, [name]: e.target.value } : el
        ),
      });
    }
  };

  const onDropdownChange = (e, name, index) => {
    const val = e.value || "";
    setDetails((prev) => ({
      ...prev,
      fields: prev.fields.map((el: any, idx: number) =>
        idx === index ? { ...el, [name]: val } : el
      ),
    }));
  };

  const handleAddOptions = (index: number) => {
    setDetails({
      ...details,
      fields: details.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              options: el.options.concat({
                name: "",
              }),
            }
          : el
      ),
    });
  };

  const handleSubOptions = (index: number) => {
    setDetails({
      ...details,
      fields: details.fields.map((el: any, idx: number) =>
        idx === index
          ? {
              ...el,
              options: el.options.slice(0, el.options.length - 1),
            }
          : el
      ),
    });
  };

  const changeHandlerOptions = (e: any, index: number, idxOp: number) => {
    setDetails({
      ...details,
      fields: details.fields.map((el: any, idx: number) =>
        idx === idxOp
          ? {
              ...el,
              options: el.options.map((op: any, i: number) =>
                i === index ? { name: e.target.value } : op
              ),
            }
          : el
      ),
    });
  };

  const changeHandlerContent = (content) => {
    setDetails((data) => ({
      ...data,
      attachments: {
        ...data.attachments,
        guide: content,
      },
    }));
  };

  const onInputCheckChange = (e) => {
    const val = e.value || false;
    let _details = { ...details };
    _details[`attachments`][`enable`] = val;
    setDetails(_details);
  };

  const handleAdd = () => {
    setDetails((prev) => ({
      ...prev,
      fields: prev.fields.concat({
        label: "",
        placeholder: "",
        typeField: "",
        options: [],
      }),
    }));
  };

  const handleSub = () => {
    setDetails((prev) => ({
      ...prev,
      fields: prev.fields.slice(0, prev.fields.length - 1),
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      let newSetting = setting.map((el) =>
        el.id === details.id ? details : el
      );

      if (data) {
        await Settings.updateSetting({
          body: {
            name: "supports",
            value: newSetting,
          },
        });
      } else {
        if (setting.length > 0) {
          await Settings.updateSetting({
            body: {
              name: "supports",
              value: [...setting, details],
            },
          });
        } else {
          await Settings.createSetting({
            body: {
              name: "supports",
              value: [details],
            },
          });
        }
      }
      setLoading(false);
      showToast(toast, "success", "Supports saved!");
      onCancle();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={details.title}
          onChange={(e) => onInputChange(e, "title")}
          required
          autoFocus
          placeholder="Title"
        />
      </div>
      <div className="flex col-12 align-items-center">
        Field:
        <div className="flex ml-2">
          <Button
            label="+"
            className="p-button-primary py-1"
            onClick={handleAdd}
          />
          <Button
            label="-"
            className="p-button-primary py-1 ml-1"
            onClick={handleSub}
          />
        </div>
      </div>
      {details.fields?.length > 0 &&
        details.fields.map((item, index) => (
          <div className="grid">
            <div className="field col-12 md:col-6">
              <label htmlFor="">Label</label>
              <InputText
                id="label"
                value={item.label}
                onChange={(e) => onInputChange(e, "label", index)}
                required
                autoFocus
                placeholder="Enter label"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="">Placeholder</label>
              <InputText
                id="placeholder"
                value={item.placeholder}
                onChange={(e) => onInputChange(e, "placeholder", index)}
                required
                autoFocus
                placeholder="Enter placeholder"
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="typeField">Type field</label>
              <Dropdown
                defaultValue={item.typeField}
                value={item.typeField}
                options={[
                  {
                    label: "Input",
                    value: "input",
                  },
                  {
                    label: "Textarea",
                    value: "textarea",
                  },
                  {
                    label: "Dropdown",
                    value: "dropdown",
                  },
                ]}
                onChange={(e) => onDropdownChange(e, "typeField", index)}
                optionLabel="label"
                placeholder="Select type"
              />
            </div>
            {item.typeField === "dropdown" && (
              <div className="field col-12">
                <div className="field col-12 md:col-4">
                  <div className="flex col-12 align-items-center">
                    Option:
                    <div className="flex ml-2">
                      <Button
                        label="+"
                        className="p-button-primary py-1"
                        onClick={() => handleAddOptions(index)}
                      />
                      <Button
                        label="-"
                        className="p-button-primary py-1 ml-1"
                        onClick={() => handleSubOptions(index)}
                      />
                    </div>
                  </div>
                  {item.options &&
                    item.options.map((el, i) => (
                      <div className="field col-12 md:col-12">
                        <label htmlFor="">Name</label>
                        <InputText
                          id="name"
                          value={el.name}
                          onChange={(e) => changeHandlerOptions(e, i, index)}
                          required
                          autoFocus
                          placeholder="Enter name"
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        ))}

      <div className="field col-12 md:col-12">
        <label htmlFor="attachments">Attachments</label>
        <br />
        <InputSwitch
          checked={details.attachments.enable}
          onChange={(e) => onInputCheckChange(e)}
        />
      </div>
      {details.attachments.enable && (
        <div className="field col-12 md:col-12">
          <label htmlFor="attachments">Guild</label>
          <br />
          <Editor
            className="h-10rem"
            value={details.attachments.guide}
            onTextChange={(e) => changeHandlerContent(e.textValue)}
          />
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
