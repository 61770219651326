import { useState, createContext } from "react";
import AttributeSetService from "services/attribute-set";
import InvestService from "services/invests";
import SettingService from "services/settings";
import StockService from "services/stocks";
import SystemService from "services/systems";
import UserService from "services/users";
import VoucherService from "services/vouchers";

type MasterContext = {
  auth?: any;
  users?: any;
  stocks?: any;
  getUsers?: any;
  getStocks?: any;
  setAuth?: any;
  notifyPending: any;
  getNotifyPending: any;
  objects: any;
  getObjects: any;
  minigames?: any;
  packages: any;
  getMinigames?: any;
  getSettings?: any;
  groups?: any;
  locations?: any;
  getLocations?: any;
  stockPrices?: any;
  getStockPrices?: any;
  getPackages?: any;
  attributeSets?: any;
  getAttributeSets?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [auth, setAuth] = useState(null);
  const [objects, setObjects] = useState([]);
  const [notifyPending, setNotifyPending] = useState({});
  const [minigames, setMinigames] = useState([]);
  const [groups, setGroups] = useState([]);
  const [locations, setLocations] = useState<any>([]);
  const [stockPrices, setStockPrices] = useState([]);
  const [attributeSets, setAttributeSets] = useState([]);

  const [packages, setPackages] = useState([{}]);

  const getPackages = () => {
    InvestService.getAll().then((res: any) => {
      if (res && res?.invests?.length > 0) {
        setPackages(res.invests);
      } else setPackages([{}]);
    });
  };

  const getLocations = () => {
    SettingService.getLocations().then((resp: any) => {
      if (resp && resp.length) {
        setLocations(resp);
      }
    });
  };

  const getStocks = () => {
    StockService.getAllCryptos().then((res: any) => {
      if (res && res.cryptos) {
        const _stocks = res.cryptos.map((s) => ({
          ...s,
          fullName: `${s.name} (${String(s.symbol).toUpperCase()})`,
        }));
        setStocks(_stocks);
      }
    });
  };

  const getStockPrices = () => {
    StockService.getStocksPrice().then((res: any) => {
      if (res && res.prices) {
        setStockPrices(res.prices);
      }
    });
  };

  const getNotifyPending = () => {
    SystemService.getCountPending().then((res: any) => {
      if (res && res.data) setNotifyPending(res.data);
    });
  };

  const getObjects = () => {
    SettingService.getSettingsByName({
      params: {
        name: "objects",
      },
    }).then((res: any) => {
      if (res && res.setting) {
        setObjects(res.setting.value);
      }
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        search: "",
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  const getMinigames = () => {
    VoucherService.getMinigames().then((res: any) => {
      setMinigames(res);
    });
  };

  const getSettings = async () => {
    const res: any = await SettingService.getSettings();
    const objects = res.find((i) => i.name === "objects");
    const groups = res.find((i) => i.name === "groups");
    setObjects(objects.value);
    setGroups(groups.value);
  };

  const getAttributeSets = () => {
    AttributeSetService.findAll().then((res: any) => {
      if (res) {
        setAttributeSets(res);
      }
    });
  };

  return (
    <MasterContext.Provider
      value={{
        auth,
        users,
        stocks,
        notifyPending,
        objects,
        minigames,
        groups,
        locations,
        stockPrices,
        packages,
        attributeSets,
        getObjects,
        getStocks,
        getUsers,
        setAuth,
        getNotifyPending,
        getMinigames,
        getSettings,
        getLocations,
        getStockPrices,
        getPackages,
        getAttributeSets,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
