/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import Service from "services/categories";
import { Dropdown } from "primereact/dropdown";
import VUpload from "components/v-upload";
import { CATEGORY_STATUS } from "utils/enum";
import { InputNumber } from "primereact/inputnumber";
import { Calendar, InputSwitch, Panel } from "primereact";
import VBanner from "components/v-banner";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading, groups, categories } =
    props;
  const emptyAdditionalData = {
    enable: false,
    name: "",
    key: "",
    group: "",
    icon: "",
    status: CATEGORY_STATUS.ENABLE,

    // attribute_id: "",
  };
  const emptyData = {
    name: "",
    key: "",
    group: "",
    description: "",
    parent_id: null,
    icon: "",
    status: CATEGORY_STATUS.ENABLE,
    order: null,
    is_show_banner: false,
    banner: {
      type: "slide" || "youtube" || "facebook",
      link: "",
      images: [],
    },
    countdown: {
      time: null,
      background: "",
    },
    // attribute_id: "",
  };

  const [details, setDetails] = useState(emptyData);
  const [additionalData, setAdditionalData] = useState(emptyAdditionalData);
  // const { attributeSets } = useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) setDetails(data);
    if (data && data.additionalDataForm)
      setAdditionalData({
        ...emptyAdditionalData,
        ...data.additionalDataForm,
      });
  }, [data]);

  const onChange = (name, value, field?) => {
    if (field) {
      setDetails((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [name]: value,
        },
      }));
    } else {
      setDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await Service.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      } else
        await Service.create({
          body: {
            ...details,
            additionalDataForm: additionalData,
          },
        });
      setLoading(false);
      showToast(toast, "success", "File saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-6">
        <label htmlFor="name">Parent</label>
        <Dropdown
          value={details.parent_id}
          options={categories}
          optionLabel="name"
          optionValue="_id"
          filter
          showClear
          onChange={(e) => onChange("parent_id", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Category Group</label>
        <Dropdown
          value={details.group}
          options={groups}
          optionLabel="name"
          optionValue="key"
          onChange={(e) => onChange("group", e.value)}
        />
      </div>
      {/* <div className="field col-6">
        <label htmlFor="attribute">Attribute</label>
        <Dropdown
          value={details.attribute_id}
          options={attributeSets?.map((k) => ({
            label: k.title,
            value: k._id,
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("attribute_id", e.value)}
        />
      </div> */}
      <div className="field col-6">
        <label htmlFor="name">Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange("key", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange("name", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label>Icon</label>
        <div className="mt-2">
          <VUpload
            urlFile={details.icon}
            setUrlFile={(file) => onChange("icon", file)}
          />
        </div>
      </div>
      <div className="field col-6">
        <label htmlFor="name">Order</label>
        <InputNumber
          id="name"
          value={details.order}
          onChange={(e) => onChange("order", e.value)}
        />
      </div>
      <div className="field col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          value={details.status}
          options={Object.keys(CATEGORY_STATUS).map((k) => ({
            label: k,
            value: CATEGORY_STATUS[k],
          }))}
          optionLabel="label"
          optionValue="value"
          onChange={(e) => onChange("status", e.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="key">Description</label>
        <InputText
          id="key"
          value={details.description}
          onChange={(e) => onChange("description", e.target.value)}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="isShowBanner">Is show banner</label>
        <br />
        <InputSwitch
          checked={details.is_show_banner}
          onChange={(e) => onChange("is_show_banner", e.value)}
        />
      </div>
      <div className="field col-12">
        <Panel header="Countdown" toggleable className="my-2">
          <div className="field col-6">
            <label htmlFor="countdown">Countdown</label>
            <Calendar
              className="m-1"
              id="countdown"
              placeholder="Countdown"
              value={
                details?.countdown?.time
                  ? new Date(details?.countdown?.time)
                  : undefined
              }
              onChange={(e: any) => onChange("time", e.value, "countdown")}
              showTime
              showSeconds
              showButtonBar
              showIcon
            />
          </div>
          <div className="field col-6">
            <VUpload
              urlFile={details?.countdown?.background}
              setUrlFile={(file) => onChange("background", file, "countdown")}
            />
          </div>
        </Panel>
      </div>

      <div className="field col-12">
        <Panel header="Banner" toggleable className="my-2">
          <div className="field col-12">
            <VBanner
              data={details?.banner}
              onChange={(value, name, field) => onChange(name, value, field)}
            />
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default forwardRef(Details);
