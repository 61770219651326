import VUpload from "components/v-upload";
import { InputText } from "primereact";
import React from "react";

type IProps = {
  data?: any;
  ref?: any;
  setData: (e: any) => void;
};

const AddNewTheme = ({ ref, data, setData }: IProps) => {
  const handleChangeInput = (type: string, value: any) => {
    setData((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="name">Name:</label>

        <InputText
          id="name"
          value={data?.name}
          onChange={(e) => handleChangeInput("name", e.target.value)}
        />
      </div>
      <div className="field col-12">
        <label htmlFor="imageDark">Url image dark:</label>
        <VUpload
          urlFile={data?.imageDark}
          setUrlFile={(e) => handleChangeInput("imageDark", e)}
        />
        {/* <InputText
          id="image_dark"
          value={data?.image_dark}
          onChange={(e) => handleChangeInput("image_dark", e.target.value)}
        /> */}
      </div>
      <div className="field col-12">
        <label htmlFor="imageLight">Url image light:</label>

        <VUpload
          urlFile={data?.imageLight}
          setUrlFile={(e) => handleChangeInput("imageLight", e)}
        />
      </div>
    </div>
  );
};

export default AddNewTheme;
