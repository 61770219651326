/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown } from "primereact";
import { renderAmount } from "utils/render";
import VAmount from "components/v-amount";
import RevenueService from "services/revenues";
import VDialog from "components/v-dialog";
import RevenueHistories from "./components/RevenueHistories";

const TYPE = [
  { name: "Leader", value: "leader" },
  { name: "Region", value: "region" },
  { name: "Province", value: "province" },
];

const defaultLazyRevHisParams = {
  first: 0,
  limit: 20,
  page: 0,
};

const View = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    type: "",
    amount: -1,
    stock: null,
    userId: null,
    amountFrom: 0,
    amountTo: 0,
    claimFrom: 0,
    claimTo: 0,
    branch: "",
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    limit: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const [lazyRevHisParams, setLazyRevHisParams]: any = useState(
    defaultLazyRevHisParams
  );

  const [totalRecords, setTotalRecords] = useState(0);
  const [sum, setSum] = useState([]);
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(0);
  const [claimFrom, setClaimFrom] = useState(0);
  const [claimTo, setClaimTo] = useState(0);
  const [revenueHistories, setRevenueHistories] = useState([]);
  const [totalRevHisRecords, setTotalRevHisRecords] = useState(0);
  const [selectedRow, setSelectedRow] = useState<any>();

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await RevenueService.search({
        body: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setSum(res.sum);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {}
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            // onClick={openNew}
            disabled
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          disabled
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left w-15rem">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1 w-15rem"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1 w-15rem"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <Dropdown
          value={globalFilter.type}
          options={TYPE}
          onChange={(e: any) => onSearch("type", e.value)}
          optionLabel="name"
          placeholder="TYPE"
          className={"w-15rem m-1"}
          showClear
        />
        <VAmount
          fieldFrom={amountFrom}
          fieldFromText={`amountFrom`}
          fieldTo={amountTo}
          fieldToText={`amountTo`}
          onChangeFieldFrom={setAmountFrom}
          onChangeFieldTo={setAmountTo}
          onChangeFilter={setGlobalFilter}
          filter={globalFilter}
          title={"Amount"}
          customClassName="w-13rem"
        />
        <VAmount
          fieldFrom={claimFrom}
          fieldFromText={`claimFrom`}
          fieldTo={claimTo}
          fieldToText={`claimTo`}
          onChangeFieldFrom={setClaimFrom}
          onChangeFieldTo={setClaimTo}
          onChangeFilter={setGlobalFilter}
          filter={globalFilter}
          title={"Claim"}
          customClassName="w-13rem"
        />
        <span className="block m-1 p-input-icon-left w-15rem">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("branch", e.target.value)}
            placeholder="Branch..."
            className="w-15rem"
          />
        </span>{" "}
      </div>
    </div>
  );
  const footer = (
    <div>
      {sum.map((s) => {
        return (
          <div>
            <span>
              {" "}
              {s?._id}: {formatNumber(Math.abs(s.totalAmount))}
            </span>
          </div>
        );
      })}
    </div>
  );

  const actionBodyTemplateList = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-sm p-button-success mr-1"
          onClick={() => {
            setSelectedRow(rowData);
            refDialogDetail.current.show();
          }}
        />
      </div>
    );
  };

  const getRevenueHistories = () => {
    RevenueService.getRevenueHistories({
      params: {
        id: selectedRow?._id,
      },
      query: {
        ...lazyRevHisParams,
        page: lazyRevHisParams.page + 1,
      },
    }).then((resp: any) => {
      if (resp && resp.docs) {
        setRevenueHistories(resp.docs);
        setTotalRevHisRecords(resp.totalDocs);
      } else {
        setRevenueHistories([]);
        setTotalRevHisRecords(0);
      }
    });
  };

  const onHideRevenueHistories = () => {
    refDialogDetail?.current?.close();
    setLazyRevHisParams(defaultLazyRevHisParams);
    setSelectedRow(undefined);
    setRevenueHistories([]);
    setTotalRevHisRecords(0);
  };

  useEffect(() => {
    if (selectedRow) {
      getRevenueHistories();
    }
  }, [lazyRevHisParams, selectedRow]);

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            footer={footer}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.limit}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => <span>{rowData.user_id?.refer_code}</span>}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "300px" }}
              body={(rowData) => <span>{rowData.user_id?.email || ""}</span>}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="amount"
              header="Amount"
              sortable
              body={(rowData) => renderAmount(rowData?.amount || 0)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "150px" }}
              field="claimed"
              header="Claimed"
              sortable
              body={(rowData) => renderAmount(rowData?.claimed || 0)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "120px" }}
              field="type"
              header="Type"
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              field="history"
              header="History"
              body={(rowData) => actionBodyTemplateList(rowData)}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "200px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Revenue Histories"
            className={`w-screen md:w-full`}
            onSubmit={onHideRevenueHistories}
          >
            <RevenueHistories
              data={revenueHistories}
              totalRecords={totalRevHisRecords}
              lazyParams={lazyRevHisParams}
              setLazyParams={setLazyRevHisParams}
              onHide={onHideRevenueHistories}
            />
          </VDialog>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
