/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
  useMemo,
} from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MasterContext } from "contexts/MasterContext";

const Objects = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    fullName: "",
    role: "USER",
    email: "",
    phone: "",
    status: "confirmed",
    password: "12345679@",
    is_leader: false,
    referred_by: "",
    percent_sales: 0,
    is_swap: true,
    is_withdraw: true,
    is_deposit: true,
    is_claim: true,
    id_region: false,
    id_province: false,
    percent_region: 0,
    percent_provice: 0,
    id_inherit: "",
  };

  const emptyInfoObject = {
    status: "pending",
    info: null,
  };

  const [details, setDetails] = useState(emptyData);
  const [objectInfo, setObjectInfo] = useState(emptyInfoObject);
  const { objects } = useContext(MasterContext);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const objectType = useMemo(
    () => objects.find((item) => item.id === data.object_type)?.name,
    [data]
  );

  useEffect(() => {
    if (data) {
      setDetails(data);
      setObjectInfo(data.object_info);
    }
  }, [data]);
  // const onInputChange = (e, name) => {
  //   const val = (e.target && e.target.value) || "";
  //   let _details = { ...details };
  //   _details[`${name}`] = val;
  //   setDetails(_details);
  // };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            object_info: objectInfo,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const renderAddress = (address) => {
    return `${address.wards || ""}, ${address.districts || ""}, ${
      address.provices || ""
    } `;
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.avatar && (
          <img
            src={objectInfo.info?.logo}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>
      <div className="field col-12 md:col-12">
        <label htmlFor="description">User email</label>
        <InputText id="email" value={details.email} disabled={true} required />
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="description">Object Type</label>
        <InputText
          id="phone"
          value={objectType}
          required
          disabled={true}
          className="capitalize"
        />
      </div>
      {objectType === "Student" && (
        <>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">School Name</label>
            <InputText
              id="phone"
              value={objectInfo.info?.schoolName}
              required
              disabled={true}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">School Code</label>
            <InputText
              id="phone"
              value={objectInfo.info?.schoolCode}
              required
              disabled={true}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="description">Specialized</label>
            <InputText
              id="phone"
              value={objectInfo.info?.specialized}
              required
              disabled={true}
            />
          </div>
        </>
      )}

      {/* {objectType === "organization" && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Organization name</label>
            <InputText
              value={objectInfo.info?.organization}
              required
              disabled={true}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Representative</label>
            <InputText
              value={objectInfo.info?.representative}
              required
              disabled={true}
            />
          </div>
        </>
      )}
      {objectType === "business" && (
        <div className="field col-12 md:col-12">
          <label htmlFor="description">Business name</label>
          <InputText
            value={objectInfo.info?.business}
            required
            disabled={true}
          />
        </div>
      )} */}
      {/* {objectType !== "Free" && objectType !== "Student" && (
        <>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Business owner</label>
            <InputText
              value={objectInfo.info?.business_owner}
              required
              disabled={true}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="description">Business code</label>
            <InputText
              value={objectInfo.info?.businessCode}
              required
              disabled={true}
            />
          </div>
        </>
      )} */}

      <div className="field col-12 md:col-6">
        <label htmlFor="description">Email</label>
        <InputText
          id="email"
          value={objectInfo.info?.email}
          disabled={true}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Phone</label>
        <InputText
          id="phone"
          value={objectInfo.info?.phone}
          disabled={true}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Address</label>
        <InputText
          value={renderAddress(objectInfo.info?.address || {})}
          disabled={true}
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Address Line</label>
        <InputText
          id="address_line"
          value={objectInfo.info?.address_line}
          disabled={true}
          required
        />
      </div>

      <div className="field col-12 md:col-12">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={objectInfo.status}
          value={objectInfo.status}
          options={[
            {
              label: "pending",
              value: "pending",
            },
            {
              label: "confirmed",
              value: "confirmed",
            },
            {
              label: "reject",
              value: "reject",
            },
          ]}
          onChange={(e) =>
            setObjectInfo((a) => ({
              ...a,
              status: e.value,
            }))
          }
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
    </div>
  );
};

export default forwardRef(Objects);
