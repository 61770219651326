/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Panel } from "primereact";
import "../styles.scss";
import CampaignService from "services/campaigns";
import { nanoid } from "nanoid";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VAffiliate from "components/v-affiliate";

const Levels = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const [details, setDetails] = useState({});
  const [levels, setLevels] = useState([]);

  const defaultLevel = {
    total_user: 0,
    enable_total_user: false,

    total_f1: 0,
    enable_total_f1: false,

    total_my_invest: 0,
    enable_total_my_invest: false,

    total_user_invest: 0,
    enable_total_user_invest: false,

    qty_user_revenue: 0,
    amount_user_revenue: 0,
    enable_user_revenue: false,

    amount_image: 0,
    enable_amount_image: false,

    amount_video: 0,
    enable_amount_video: false,

    amount_attachment: 0,
    enable_amount_attachment: false,

    enable_percent_address: false,
    percent_province: 0,

    enable_percent_leader: false,
    percent_leader: 0,

    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
    },

    affiliates: {},
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setLevels(data.levels);
    }
  }, [data]);

  const handleAddLevel = () => {
    setLevels((prev) => [
      ...prev,
      {
        id: `LEVEL_${nanoid(6).toUpperCase()}`,
        ...defaultLevel,
      },
    ]);
  };

  const handleSubLevel = () => {
    setLevels((prev) => prev.slice(0, prev.length - 1));
  };

  const handleChangeLevel = (id) => (name, value) => {
    let _levels = levels.map((p) =>
      p.id === id
        ? {
            ...p,
            [name]: value,
          }
        : p
    );
    setLevels(_levels);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await CampaignService.editCampaign({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            levels: levels,
          },
        });
      } else {
        await CampaignService.createCampaign({
          body: {
            ...details,
            levels: levels,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <div className="col-12">
        <Button
          icon="bx bxs-add-to-queue"
          className="p-button-rounded p-button-success"
          aria-label="Search"
          onClick={handleAddLevel}
        />
        <Button
          icon="bx bxs-minus-circle"
          className="p-button-rounded p-button-danger"
          aria-label="Cancel"
          onClick={handleSubLevel}
        />
      </div>
      {levels.map((level, index) => (
        <Panel
          header={`Level ${index + 1}`}
          toggleable
          className="my-2"
          collapsed={true}
        >
          <div className="grid">
            <div className="grid col-12">
              <div className="field col-6">
                <label htmlFor="time_lock">ID</label>
                <InputText id="id" value={level.id} required disabled />
              </div>
              <div className="field col-6">
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={level.name}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("name", e.target.value)
                  }
                />
              </div>

              <div className="field col-12">
                <label htmlFor="desc">Description</label>
                <InputTextarea
                  value={level.description}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("description", e.target.value)
                  }
                />
              </div>

              <div className="field col-6">
                <VUpload
                  label="Logo level"
                  urlFile={level.logoLevel}
                  setUrlFile={(file) =>
                    handleChangeLevel(level.id)("logoLevel", file)
                  }
                />
              </div>
              <div className="field col-6">
                <VUpload
                  label="Background level"
                  urlFile={level.backgroundLevel}
                  setUrlFile={(file) =>
                    handleChangeLevel(level.id)("backgroundLevel", file)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="total_user">Total user</label>
                <InputNumber
                  id="total_user"
                  value={level.total_user}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("total_user", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_total_user}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("enable_total_user", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="total_f1">Total F1</label>
                <InputNumber
                  id="total_f1"
                  value={level.total_f1}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("total_f1", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_total_f1}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("enable_total_f1", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="total_my_invest">Total my invest</label>
                <InputNumber
                  id="total_my_invest"
                  value={level.total_my_invest}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("total_my_invest", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_total_my_invest}
                  onChange={(e) =>
                    handleChangeLevel(level.id)(
                      "enable_total_my_invest",
                      e.value
                    )
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="total_user_invest">Total user invest</label>
                <InputNumber
                  id="total_user_invest"
                  value={level.total_user_invest}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("total_user_invest", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_total_user_invest}
                  onChange={(e) =>
                    handleChangeLevel(level.id)(
                      "enable_total_user_invest",
                      e.value
                    )
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="percent_leader">Percent leader</label>
                <InputNumber
                  prefix="%"
                  id="percent_leader"
                  value={level.percent_leader}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("percent_leader", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_percent_leader}
                  onChange={(e) =>
                    handleChangeLevel(level.id)(
                      "enable_percent_leader",
                      e.value
                    )
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="percent_province">Percent province</label>
                <InputNumber
                  id="percent_province"
                  value={level.percent_province}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("percent_province", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_percent_address}
                  onChange={(e) =>
                    handleChangeLevel(level.id)(
                      "enable_percent_address",
                      e.value
                    )
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="amount_image">Images</label>
                <InputNumber
                  id="amount_image"
                  value={level.amount_image}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("amount_image", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_amount_image}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("enable_amount_image", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="amount_video">Videos</label>
                <InputNumber
                  id="amount_video"
                  value={level.amount_video}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("amount_video", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_amount_video}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("enable_amount_video", e.value)
                  }
                />
              </div>
            </div>

            <div className="grid col-12 md:col-6">
              <div className="field col-8">
                <label htmlFor="amount_attachment">Attachments</label>
                <InputNumber
                  id="amount_attachment"
                  value={level.amount_attachment}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("amount_attachment", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_amount_attachment}
                  onChange={(e) =>
                    handleChangeLevel(level.id)(
                      "enable_amount_attachment",
                      e.value
                    )
                  }
                />
              </div>
            </div>

            <div className="grid col-12 ">
              <div className="field col-4">
                <label htmlFor="qty_user_revenue">Qty Branch Invest</label>
                <InputNumber
                  id="qty_user_revenue"
                  value={level.qty_user_revenue}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("qty_user_revenue", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <label htmlFor="amount_user_revenue">Amount Branch Invest</label>
                <InputNumber
                  id="amount_user_revenue"
                  value={level.amount_user_revenue}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("amount_user_revenue", e.value)
                  }
                />
              </div>
              <div className="field col-4">
                <br />
                <br />
                <InputSwitch
                  checked={level.enable_user_revenue}
                  onChange={(e) =>
                    handleChangeLevel(level.id)("enable_user_revenue", e.value)
                  }
                />
              </div>
            </div>

            <div className="field col-12">
              <Panel
                header="Reward"
                toggleable
                className="my-2"
                collapsed={true}
              >
                <VReward
                  reward={level.reward}
                  setReward={(reward) =>
                    handleChangeLevel(level.id)("reward", reward)
                  }
                />
              </Panel>
            </div>
            <div className="field col-12">
              <Panel header="Affilate" toggleable collapsed={true}>
                <VAffiliate
                  affiliates={level.affiliates}
                  setAffiliates={(a) =>
                    handleChangeLevel(level.id)("affiliates", a)
                  }
                />
              </Panel>
            </div>
          </div>
        </Panel>
      ))}
    </>
  );
};

export default forwardRef(Levels);
