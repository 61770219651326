/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { renderAmount, renderText } from "utils/render";
import debounce from "lodash.debounce";
import { Calendar, Dropdown, InputText } from "primereact";
import TradeService from "services/trades";
import VStockDropdown from "components/v-stock";

const STATUS = [
  { name: "Pending", value: "pending" },
  { name: "Success", value: "success" },
  { name: "Error", value: "error" },
];

const Crud = () => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: "success",
    type: "withdraw_trade",
    stock: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 20,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await TradeService.getWithdrawHistory({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {}
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div className="grid my-2 align-items-center">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="Start Date"
          value={globalFilter.start}
          onChange={(e: any) => onSearch("start", e.value)}
          showTime
          showSeconds
          showButtonBar
        />{" "}
        <Calendar
          className="m-1"
          id="time24"
          placeholder="End Date"
          value={globalFilter.end}
          onChange={(e: any) => onSearch("end", e.value)}
          showTime
          showSeconds
          showButtonBar
        />
        <VStockDropdown
          value={globalFilter.stock}
          setValue={(e) => onSearch("stock", e)}
          className={"w-15rem m-1"}
        />
        <Dropdown
          value={globalFilter.status}
          options={STATUS}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="name"
          placeholder="Status"
          className={"w-15rem m-1"}
        />
      </div>
    </div>
  );

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />

          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              field="user.refer_code"
              header="Id"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) => <span>{rowData?.user?.refer_code || ""}</span>}
            />
            <Column
              field="user.email"
              header="Email"
              style={{ flexGrow: 1, flexBasis: "250px" }}
              body={(rowData) => (
                <span>{renderText(rowData?.user?.email, 30)}</span>
              )}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              field="token"
              header="Stock"
              body={(rowData) => <>{String(rowData?.token)?.toUpperCase()}</>}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amount"
              header="Amount"
              body={(rowData) => <>{renderAmount(rowData.amount || 0)}</>}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amountLocked"
              header="Amount Locked"
              body={(rowData) => <>{renderAmount(rowData.amountLocked || 0)}</>}
            />
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="amountReceived"
              header="Amount Received"
              body={(rowData) => (
                <>{renderAmount(rowData.amountReceived || 0)}</>
              )}
            />
            <Column
              header="Status"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            />
            <Column
              field="createdAt"
              header="Created At"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => rowData.createdAt}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
