/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputText, Toast } from "primereact";
import { Editor } from "primereact/editor";
import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";

const Home = () => {
  const [state, setState] = useState({
    user: {},
    phone: "",
    company: "",
    language: "",
    copyright: "",
    ssl: "",
    error: {},
    logo: "",
    loginLogo: "",
    email: "",
    fevicon: "",
    youtube_id_bg: "",
    telegram: "",
    twiter: "",
    youtobe: "",
    facebook: "",
    name: "",
    address: "",
    business_code: "",
    agent_name: "",
    decriptions: "",
    introduce: "",
  });
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res: any = await SettingService.getSettingsByName({
      params: {
        name: "landing_page",
      },
    });

    if (res && res.setting) {
      setState({
        ...state,
        ...res.setting.value,
      });
    }
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await SettingService.updateSetting({
        body: {
          name: "landing_page",
          value: state,
        },
      });
      setLoading(false);
      getData();
      showToast(toast, "success", "Update success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    try {
      getData();
    } catch (error) {}
  };
  const OnChangeEditor = (e) => {
    setState((data) => ({
      ...data,
      introduce: e.htmlValue,
    }));
  };

  return (
    <div className="grid">
      <Toast ref={toast} />

      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Home</h4>
          </div>
          <div className="grid">
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="name"
                  className="w-full"
                  value={state.company}
                  name="company"
                  onChange={handleChange}
                />
                <label htmlFor="company">Company Name</label>
              </span>
            </div>

            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.logo}
                  name="logo"
                  onChange={handleChange}
                />
                <label htmlFor="logo">Logo</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.email}
                  name="email"
                  onChange={handleChange}
                />
                <label htmlFor="email">Primary Email</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.phone}
                  name="phone"
                  onChange={handleChange}
                />
                <label htmlFor="phone">Phone</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.youtube_id_bg}
                  name="youtube_id_bg"
                  onChange={handleChange}
                />
                <label htmlFor="idYoutube">ID Youtube Background</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.youtobe}
                  name="youtobe"
                  onChange={handleChange}
                />
                <label htmlFor="youtube">Youtube</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.telegram}
                  name="telegram"
                  onChange={handleChange}
                />
                <label htmlFor="telegram">Telegram</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.facebook}
                  name="facebook"
                  onChange={handleChange}
                />
                <label htmlFor="facebook">Facebook</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.twiter}
                  name="twiter"
                  onChange={handleChange}
                />
                <label htmlFor="twitter">Twitter</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.decriptions}
                  name="decriptions"
                  onChange={handleChange}
                />
                <label htmlFor="">Decription</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.agent_name}
                  name="agent_name"
                  onChange={handleChange}
                />
                <label htmlFor="">Agent Name</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.business_code}
                  name=""
                  onChange={handleChange}
                />
                <label htmlFor="">Business Code</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.name}
                  name="name"
                  onChange={handleChange}
                />
                <label htmlFor="">Name</label>
              </span>
            </div>
            <div className="field col-12 md:col-6">
              <span className="p-float-label">
                <InputText
                  id="logo"
                  className="w-full"
                  value={state.address}
                  name=""
                  onChange={handleChange}
                />
                <label htmlFor="">Address</label>
              </span>
            </div>
            <div className="w-full pb-3 px-2">
              <span className="">
                <label htmlFor="">Introduce</label>

                <Editor
                  value={state?.introduce}
                  onTextChange={OnChangeEditor}
                  className="w-full pt-2"
                />
              </span>
            </div>
          </div>

          <div className="">
            <Button
              label="Cancel"
              className="p-button-danger mr-2"
              loading={loading}
              onClick={handleCancel}
            />
            <Button label="Submit" loading={loading} onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
