import { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import AllotmentPerQnt from "./components/AllotmentPerQnt";

const Airdrop = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => {
        setActiveIndex(e.index);
      }}
    >
      <TabPanel header="Percent">
        <AllotmentPerQnt type={"percent"} />
      </TabPanel>
      <TabPanel header="Quantity">
        <AllotmentPerQnt type={"quantity"} />
      </TabPanel>
      <TabPanel header="Rate">
        <AllotmentPerQnt type={"rate"} />
      </TabPanel>
    </TabView>
  );
};

export default Airdrop;
