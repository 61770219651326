/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import VUpload from "components/v-upload";
import { Button, Calendar, InputSwitch, Panel } from "primereact";
import StockService from "services/stocks";

const StockInformation = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    introduce: {
      type: "slide",
      link_video: "",
      images: ["", "", ""],
    },
    social: {
      facebook: "",
      telegram: "",
      instagram: "",
      twitter: "",
    },
    unlockAt: null,
    trade_status: "disable",
  };

  const socialData = [
    {
      icon: "pi pi-facebook",
      label: "Facebook",
      value: "facebook",
    },
    {
      icon: "pi pi-telegram",
      label: "Telegram",
      value: "telegram",
    },
    {
      icon: "pi pi-twitter",
      label: "Twitter",
      value: "twitter",
    },
    {
      icon: "pi pi-instagram",
      label: "Instagram",
      value: "instagram",
    },
  ];

  const [information, setInformation] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  //   TIMES COUNTDOWN
  const [isUnlocked, setIsUnlocked] = useState(false);
  const _isTrade =
    new Date(information.unlockAt).getTime() <= new Date().getTime();

  useEffect(() => {
    if (data) {
      const _tradeStatus =
        new Date(data.unlockAt).getTime() <= new Date().getTime();
      setInformation({
        ...emptyData,
        ...data,
        trade_status: !data.unlockAt
          ? "disable"
          : _tradeStatus
          ? "enable"
          : "pending",
      });
    }
  }, [data]);

  useEffect(() => {
    if (information.trade_status !== "disable") {
      setIsUnlocked(true);
    } else setIsUnlocked(false);
  }, [information]);

  const onCalendarChange = (e, name) => {
    const val = (e.target && e.target.value) || "";

    let _information = { ...information };
    _information[`${name}`] = val;
    setInformation(_information);
  };

  //   SOCIAL
  const onSocialInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";

    let _information = { ...information };
    _information.social[`${name}`] = val;
    setInformation(_information);
  };

  //   INTRODUCE
  const onIntroduceInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _information = { ...information };
    _information.introduce[`${name}`] = val;
    setInformation(_information);
  };

  const onIntroduceDropdownChange = (e, name) => {
    const val = e.value || "";
    let _information = { ...information };
    _information.introduce[`${name}`] = val;
    setInformation(_information);
  };

  const handleImageChange = (url: string, index: number) => {
    setInformation((prev) => {
      return {
        ...prev,
        introduce: {
          ...prev.introduce,
          images: prev.introduce.images.map((u, i) => (i === index ? url : u)),
        },
      };
    });
  };

  const handleSubIndex = () => {
    try {
      setInformation((prev) => {
        return {
          ...prev,
          introduce: {
            ...prev.introduce,
            images: prev.introduce.images.slice(
              0,
              prev.introduce.images.length - 1
            ),
          },
        };
      });
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };
  const handleAddIndex = () => {
    try {
      setInformation((prev) => {
        return {
          ...prev,
          introduce: {
            ...prev.introduce,
            images: [...prev.introduce.images, ""],
          },
        };
      });
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await StockService.updateCryptos({
          params: {
            id: data._id,
          },
          body: {
            ...data,
            ...information,
            trade_status: !isUnlocked
              ? "disable"
              : _isTrade
              ? "enable"
              : "pending",
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Stock saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {data.logo && (
          <img
            src={data.logo}
            alt={data.logo}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>

      <div className="text-center text-xl font-bold field col-12">
        <label htmlFor="name">Stock: </label>
        <span>{data.name}</span>
      </div>
      <div className="field col-12">
        <Panel header="Introduce" toggleable collapsed={true}>
          <div className="field col-12">
            <label htmlFor="type">Introduce type</label>
            <Dropdown
              value={information.introduce.type}
              options={[
                { label: "Slide", value: "slide" },
                { label: "Video", value: "video" },
              ]}
              onChange={(e) => onIntroduceDropdownChange(e, "type")}
              optionLabel="label"
              placeholder="Select type"
            />
          </div>
          {information.introduce.type === "video" && (
            <div className="field col-12">
              <label htmlFor="link_video">Link Video</label>
              <InputText
                id="link_video"
                value={information.introduce.link_video}
                onChange={(e) => onIntroduceInputChange(e, "link_video")}
                required
                autoFocus
              />
            </div>
          )}

          {information.introduce.type === "slide" && (
            <div className="field col-12 text-center font-bold">
              <label htmlFor="images">Images</label>
              {information.introduce.images.map((url, index) => (
                <div className="field col-12">
                  <VUpload
                    urlFile={url}
                    setUrlFile={(e) => handleImageChange(e, index)}
                  />
                </div>
              ))}
              <div className="grid">
                <div className="d-flex field col-12">
                  <Button
                    icon="pi pi-plus"
                    className="p-button"
                    aria-label="Add"
                    onClick={(_) => handleAddIndex()}
                  />
                  <Button
                    icon="pi pi-minus"
                    className="p-button ml-1"
                    aria-label="Cancel"
                    onClick={(_) => handleSubIndex()}
                  />
                </div>
              </div>
            </div>
          )}
        </Panel>
      </div>

      <div className="field col-12">
        <Panel header="Social" toggleable collapsed={true}>
          {socialData.map((el: any, index: any) => (
            <div key={index} className="field col-12">
              <div className="flex align-items-center">
                <Button
                  className="p-button-text p-button-secondary"
                  icon={el.icon}
                />
                <label htmlFor={el.value}>{el.label}</label>
              </div>
              <InputText
                id={el.value}
                value={information.social[el.value]}
                onChange={(e) => onSocialInputChange(e, `${el.value}`)}
                required
                autoFocus
              />
            </div>
          ))}
        </Panel>
      </div>

      <div className="field col-12">
        <Panel header="Times unlock trade" toggleable collapsed={true}>
          <div className="field col-12 ">
            <div className="flex justify-content-between align-items-center w-full">
              <div className="flex">
                <label htmlFor="stockStatus">Stock trading status: </label>
                <span className="font-bold ml-2">
                  {information.trade_status}
                </span>
              </div>
              <div className="flex flex-column">
                <label htmlFor="enableTrade" className="mb-2">
                  Enable
                </label>
                <InputSwitch
                  checked={isUnlocked}
                  onChange={(e) => setIsUnlocked(e.value)}
                />
              </div>
            </div>
          </div>
          {isUnlocked && (
            <div className="field col-12">
              <label htmlFor="unlockAt">Unlock at:</label>
              <Calendar
                className="m-1"
                id="time24"
                placeholder={
                  formatLocalTime(data.unlockAt) || formatLocalTime(new Date())
                }
                value={
                  information.unlockAt
                    ? new Date(information.unlockAt)
                    : undefined
                }
                onChange={(e: any) => onCalendarChange(e, "unlockAt")}
                showTime
                showSeconds
                showButtonBar
              />
            </div>
          )}
        </Panel>
      </div>
    </div>
  );
};

export default forwardRef(StockInformation);
