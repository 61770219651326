import VConfirm from "components/v-confirm";
import VDialog from "components/v-dialog";
import { Button, Column, DataTable, Image, Toast } from "primereact";
import React, { useEffect, useRef, useState } from "react";
import SettingService from "services/settings";
import Details from "./components/Details";

const Banks = () => {
  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  const [data, setData] = useState(null);
  const [details, setDetails] = useState(null);

  const [globalFilter] = useState({
    search: null,
    start: null,
    end: null,
  });
  const [lazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "order",
    sortOrder: 1,
  });

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await SettingService.getSettingsByName({
        params: {
          name: "bank_cards",
        },
      });
      if (res) {
        setData(res.setting.value);
      }
    } catch (error) {}
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetails(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetails({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
    setDetails(null);
  };
  const onSubmitDialogDetail = () => {
    refDetail.current.submit();
    refDialogDetail.current.close();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  //   Delete
  const confirmDelete = (product) => {
    setDetails(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    const _data = data.filter((el) => el._id !== details._id);
    await SettingService.updateSetting({
      body: {
        name: "bank_cards",
        value: [..._data],
      },
    });
    loadData();
    refDialogDelete.current.close();
    // setDetails(null);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  //   const header = (
  //     <Panel header="Filter" toggleable collapsed={false}>
  //       <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
  //         <div className="grid my-2 align-items-center w-full">
  //           <span className="block p-input-icon-left col-12 md:col-6">
  //             <i className="ml-1 pi pi-search" />
  //             <InputText
  //               className="w-full"
  //               type="search"
  //               //   onInput={(e: any) => onSearch("search", e.target.value)}
  //               placeholder="Search..."
  //             />
  //           </span>{" "}
  //           {/* <div className="col-12 md:col-6">
  //                 <Dropdown
  //                   value={globalFilter.status}
  //                   options={STATUS}
  //                   onChange={(e: any) => onSearch("status", e.value)}
  //                   optionLabel="name"
  //                   placeholder="Status"
  //                   className={"w-full"}
  //                   showClear
  //                 />
  //               </div>
  //               <div className="col-6">
  //                 <Calendar
  //                   className="w-full"
  //                   id="time24"
  //                   placeholder="Start Date"
  //                   value={globalFilter.start}
  //                   onChange={(e: any) => onSearch("start", e.value)}
  //                   showTime
  //                   showSeconds
  //                   showButtonBar
  //                 />{" "}
  //               </div>
  //               <div className="col-6">
  //                 <Calendar
  //                   className="w-full"
  //                   id="time24"
  //                   placeholder="End Date"
  //                   value={globalFilter.end}
  //                   onChange={(e: any) => onSearch("end", e.value)}
  //                   showTime
  //                   showSeconds
  //                   showButtonBar
  //                 />
  //               </div> */}
  //         </div>
  //       </div>
  //     </Panel>
  //   );
  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <DataTable
            ref={dt}
            value={data}
            footer={leftToolbarTemplate}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "250px" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "250px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Front background"
              body={(rowData) =>
                rowData.frontBackground && (
                  <Image
                    src={rowData.frontBackground}
                    alt="Image Text"
                    width="50"
                    height="50"
                    preview
                  />
                )
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Back background"
              body={(rowData) =>
                rowData.backBackground && (
                  <Image
                    src={rowData.backBackground}
                    alt="Image Text"
                    width="50"
                    height="50"
                    preview
                  />
                )
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "80px" }}
              header="Logo"
              body={(rowData) =>
                rowData.logo && (
                  <Image
                    src={rowData.logo}
                    alt="Image Text"
                    width="50"
                    height="50"
                    preview
                  />
                )
              }
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Card details"
            onSubmit={onSubmitDialogDetail}
            onCancel={onCancelDialogDetail}
          >
            <Details
              ref={refDetail}
              data={data}
              details={details}
              setDetails={setDetails}
              setData={setData}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
      {/* {details && (
        <div className="field col-12 text-center">
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-danger mr-2"
          ></Button>
          <Button
            label="Save"
            icon="pi pi-save"
            className="p-button-success mr-2"
            onClick={submit}
          ></Button>
        </div>
      )} */}
    </div>
  );
};

export default Banks;
