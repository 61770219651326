import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { forwardRef, useContext, useEffect } from "react";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { showToast } from "utils/common";
import StockService from "services/stocks";
import { MasterContext } from "contexts/MasterContext";

const Roles = (props, ref) => {
  const { stock, toast, onCancel, setLoading } = props;

  const { stocks } = useContext(MasterContext);
  const [data, setData] = useState(null);
  const [pairs, setPairs] = useState([]);
  const [sellPairs, setSellPairs] = useState([]);

  useEffect(() => {
    if (stock) {
      StockService.getCryptosById({
        params: {
          id: stock._id,
        },
      }).then((data: any) => {
        setData(data.crypto);
        if (data.crypto.pairs) {
          setPairs(data.crypto.pairs);
        }
        if (data.crypto.sell_pairs) {
          setSellPairs(data.crypto.sell_pairs);
        }
      });
    }
  }, [stock]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submitHandler();
    },
  }));

  const handleSelect = (e: any, p: string) => {
    setPairs({
      ...pairs,
      [p]: e.checked,
    });
  };

  const handleSelectSellPairs = (e: any, p: string) => {
    setSellPairs({
      ...sellPairs,
      [p]: e.checked,
    });
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      await StockService.updateCryptos({
        params: {
          id: data._id,
        },
        body: {
          ...data,
          pairs,
          sell_pairs: sellPairs
        },
      });
      onCancel();
      showToast(toast, "success", "Stock save!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };


  return (
    <div>
      <Card header={<h5>Stock : {stock.name}</h5>}>
        <DataTable
          value={(stocks || []).filter((stock) => stock.symbol !== data?.symbol)}
          responsiveLayout="scroll"
          paginator
          rows={20}
          rowsPerPageOptions={[10, 20, 50]}
          showGridlines
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        >
          <Column
            field="stock"
            header="Stock"
            body={(rowData) => rowData.symbol}
          ></Column>
          <Column
            field="access"
            header="Access"
            body={(rowData) => (
              <Checkbox
                inputId="binary"
                checked={pairs && pairs[rowData.symbol]}
                onChange={(e) => handleSelect(e, rowData.symbol)}
              />
            )}
          ></Column>
          <Column
            field="stock"
            header="Sell Unit"
            body={(rowData) => (
              <Checkbox
                inputId="binary"
                checked={sellPairs && sellPairs[rowData.symbol]}
                onChange={(e) => handleSelectSellPairs(e, rowData.symbol)}
              />
            )}
          ></Column>
        </DataTable>
      </Card>
    </div>
  );
};

export default forwardRef(Roles);
