import VReward from "components/v-reward";
import { nanoid } from "nanoid";
import { Button, Dropdown } from "primereact";
import { useEffect, useState } from "react";
import { LEVELS, REWARD } from "utils/constant";

const View = (props: { affiliates: any; setAffiliates: any }) => {
  const { affiliates, setAffiliates } = props;
  const [isConfirm, setIsConfirm] = useState(true);

  const [state, setState] = useState([]);

  useEffect(() => {
    if (affiliates) {
      const _affiliates = affiliates || {};
      const array = Object.keys(_affiliates).map((key, index) => {
        return {
          index,
          level: key,
          reward: _affiliates[key],
        };
      });
      setState(array);
    }
  }, [affiliates]);

  const handleAdd = () => {
    setState((state) => [
      ...state,
      { index: nanoid(), level: "", reward: REWARD },
    ]);
  };

  const handleSub = () => {
    setState((state) => state.slice(0, state.length - 1));
  };

  const changeHandler = (index, name, value) => {
    setIsConfirm(false);
    setState((state) => {
      return state.map((item) =>
        item.index === index
          ? {
              ...item,
              [name]: value,
            }
          : item
      );
    });
  };

  const changeReward = (index, data) => {
    setIsConfirm(false);
    setState((state) => {
      return state.map((item) =>
        item.index === index
          ? {
              ...item,
              reward: data,
            }
          : item
      );
    });
  };

  const handleConfirm = () => {
    const affiliates = state.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.level]: cur.reward,
      }),
      {}
    );
    setAffiliates(affiliates);
    setIsConfirm(true);
  };

  return (
    <div className="grid">
      <div className="col-12">
        <Button
          icon="bx bxs-add-to-queue"
          className="p-button-rounded p-button-success"
          aria-label="Search"
          onClick={(_) => handleAdd()}
        />
        <Button
          icon="bx bxs-minus-circle"
          className="p-button-rounded p-button-danger mx-2"
          aria-label="Cancel"
          onClick={(_) => handleSub()}
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded"
          aria-label="Filter"
          disabled={isConfirm}
          onClick={(_) => handleConfirm()}
        />
      </div>
      {state.map((item, key) => (
        <div key={key} className="w-full grid">
          <div className="field col-12 md:col-3">
            <label htmlFor="website">Stock</label>
            <Dropdown
              value={item.level}
              options={LEVELS}
              onChange={(e) => changeHandler(item.index, "level", e.value)}
              optionLabel="name"
              filterBy="name"
              optionValue="value"
              filter
              showClear
              placeholder="Select level"
            />
          </div>
          <div className="field col-12 md:col-9">
            <div className="card">
              <VReward
                reward={item.reward}
                setReward={(reward) => changeReward(item.index, reward)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default View;
